import React from 'react'
import styled from 'styled-components'

const StyledErrorImage = styled.svg`
  height: auto;
  width: 90%;
  max-width: 489px;
`

function Error_large() {
  return (
    <StyledErrorImage xmlns="http://www.w3.org/2000/svg" width="489" height="236" fill="none" viewBox="0 0 489 236">
      <path
        fill="#88D2D3"
        fillRule="evenodd"
        d="M411.369 197.295H381.64a10.97 10.97 0 00-9.641 5.724c.45-.037.922-.032 1.463.082 1.384.292 2.551 1.313 3.572 2.114 1.022.803 1.022 3.21.948 4.813-.073 1.604-1.75 4.448-3.062 6.198-.847 1.129-2.902 3.406-4.26 4.883v13.384h35.114v-26.222c0-4.241 2.41-7.914 5.931-9.741l-.336-1.235z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M424.924 198.17h23.068c3.59 0 6.74 1.888 8.533 4.718l1.722-.531c-2.052-3.543-5.874-5.937-10.255-5.937h-23.565l.497 1.75zM373.043 202.995c1.779-2.889 4.963-4.825 8.596-4.825h29.905l-.495-1.75h-29.41c-4.552 0-8.509 2.585-10.493 6.36l1.897.215zM458.094 216.723v16.895h-86.557V220.43l-1.75 1.75v12.313c0 .483.392.875.875.875h88.307a.875.875 0 00.875-.875v-18.309l-1.75.539z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M424.924 198.17h23.068c3.59 0 6.74 1.888 8.533 4.718l1.722-.531c-2.052-3.543-5.874-5.937-10.255-5.937h-23.565l.497 1.75zM458.093 216.723v16.895h-51.445v-25.346c0-3.813 2.123-7.136 5.25-8.856l-.486-1.712c-3.859 1.957-6.514 5.954-6.514 10.568v26.221c0 .483.391.875.875.875h53.195a.875.875 0 00.875-.875v-18.309l-1.75.539z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M415.847 214.22a.878.878 0 01-.842-.637l-7.304-25.757a.873.873 0 01.079-.665.87.87 0 01.523-.415l12.385-3.512a.872.872 0 011.081.604l4.366 15.398a.873.873 0 01-.603 1.08.872.872 0 01-1.082-.604l-4.126-14.555-10.702 3.033 6.829 24.073 11.539-3.272a.875.875 0 11.48 1.683l-12.383 3.512a.854.854 0 01-.24.034zM469.786 213.12l-4.03-13.076a.877.877 0 00-1.094-.579l-23.338 7.192a.878.878 0 00-.516.427.883.883 0 00-.061.667l.938 3.045 1.976.467-.984-3.192 21.665-6.676 3.514 11.405-21.663 6.676-.345-1.115-.695 2.94c.02.012.03.032.051.042a.86.86 0 00.667.063l23.338-7.192a.873.873 0 00.577-1.094z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M443.412 229.539a.865.865 0 01-.2-.023l-18.09-4.261a.875.875 0 01.399-1.703l17.24 4.06 3.508-14.833-11.628-2.749a.878.878 0 01-.65-1.054.878.878 0 011.054-.651l12.479 2.953c.469.112.76.582.649 1.053l-3.911 16.534a.872.872 0 01-.85.674z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#88D2D3"
        fillRule="evenodd"
        d="M388.424 234.492h26.875c8.751 0 16.217-3.862 20.594-9.009h46.079c-4.377 5.147-11.842 9.009-20.594 9.009H388.424z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M425.391 233.617h35.987c7.128 0 13.926-2.684 18.585-7.259h-43.671c-2.853 3.24-6.64 5.728-10.901 7.259zm35.987 1.75h-72.954a.875.875 0 010-1.75h26.875c7.847 0 15.296-3.253 19.927-8.701a.872.872 0 01.667-.308h46.079a.874.874 0 01.667 1.441c-4.96 5.834-12.908 9.318-21.261 9.318z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M414.14 235.046a.875.875 0 01-.875-.875V226.7l-7.785-5.673a.876.876 0 111.032-1.414l8.145 5.934a.881.881 0 01.358.709v7.915a.875.875 0 01-.875.875z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M443.213 197.423l-3.733 3.733 3.733-3.733z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M439.481 202.03a.884.884 0 01-.62-.255.878.878 0 010-1.239l3.733-3.731a.872.872 0 011.237 0 .872.872 0 010 1.237l-3.731 3.733a.88.88 0 01-.619.255z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M437.671 197.423l-2.42 2.42 2.42-2.42z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M435.251 200.718a.876.876 0 01-.619-1.495l2.42-2.418a.872.872 0 011.237 0 .872.872 0 010 1.237l-2.418 2.42a.88.88 0 01-.62.256z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M448.925 197.423l-4.62 4.62 4.62-4.62z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M444.305 202.917a.875.875 0 01-.619-1.494l4.62-4.618a.874.874 0 111.237 1.237l-4.618 4.62a.88.88 0 01-.62.255z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M453.213 198.847l-2.443 2.441 2.443-2.441z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M450.77 202.164a.88.88 0 01-.62-.256.878.878 0 010-1.239l2.443-2.441a.876.876 0 011.238 1.237l-2.442 2.443a.876.876 0 01-.619.256zM477.391 235.486h-21.808a.875.875 0 010-1.75h21.808a.875.875 0 010 1.75z"
        clipRule="evenodd"
      ></path>
      <mask id="mask0" width="7" height="3" x="482" y="233" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M482.204 233.736h6.774v1.75h-6.774v-1.75z"></path>
      </mask>
      <g mask="url(#mask0)">
        <path
          fill="#394A58"
          fillRule="evenodd"
          d="M488.103 235.486h-5.024a.875.875 0 010-1.75h5.024a.875.875 0 010 1.75z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#88D2D3"
        fillRule="evenodd"
        d="M276.546 182.771l-5.833 3.792-5.832-3.792v-11.958h11.665v11.958zm-28.859 0l-5.833 3.792-5.834-3.792v-11.958h11.667v11.958zm57.624 32.874c-.873-3.938-1.64-12.032-2.952-19.469-1.313-7.438-4.484-17.829-6.344-25.594-1.859-7.766-4.375-13.235-6.235-16.735-1.595-3.001-6.047-7.726-8.421-9.188 0 0-5.25-2.952-10.063-3.608-.04-.007-.11-.007-.156-.012-.742 1.996-1.596 3.631-2.553 5.006l1.834 17.913h-9.625c-2.915 0-5.395 1.75-5.395 3.646 0-1.896-2.48-3.646-5.395-3.646h-9.625l1.895-18.52h.542a22.046 22.046 0 01-2.21-4.706l-.738-1.687s-6.535-2.905-11.449-6.125c-4.447-2.917-8.312-6.417-10.391-10.136 2.187-8.531 6.947-15.624 9.408-19.031 1.421-1.969 1.494-3.682.619-5.76l3.561-3.511-3.123-8.88c-4.521 1.866-9.043 6.449-13.309 11.151-4.265 4.704-7.109 9.625-9.734 14.11-2.625 4.484-3.718 8.859-3.937 11.703-.219 2.843.327 6.672 2.296 10.5 1.969 3.829 6.781 10.172 11.485 15.641 4.702 5.469 13.125 11.169 13.125 11.169l1.75 5.773.366 6.064-.949 24.645 53.029-.074-.054-8.914c.213.318.554-.541.764-.163 3.5 6.288 7.548 18.594 9.954 24.938 2.407 6.343 12.905 7.437 12.03 3.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFC226"
        fillRule="evenodd"
        d="M224.301 130.655c-1.668.804-2.893 2.041-3.847 3.649l5.453 4.183c1.141-1.754 2.294-3.054 3.876-3.85l-5.482-3.982z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFC226"
        fillRule="evenodd"
        d="M221.624 134.1l4.081 3.129c.826-1.157 1.628-2.007 2.507-2.653l-3.987-2.895c-1.015.576-1.873 1.374-2.601 2.419zm4.283 5.26a.867.867 0 01-.532-.18l-5.455-4.181a.876.876 0 01-.219-1.141c1.092-1.842 2.473-3.148 4.22-3.99a.875.875 0 01.894.079l5.482 3.981a.87.87 0 01.357.779.87.87 0 01-.476.711c-1.282.647-2.339 1.708-3.538 3.545a.868.868 0 01-.733.397zM293.828 164.128c-1.495 3.5-3.719 4.704-7.072 5.469l2.04 6.563c2.954-.548 5.25-2.079 6.89-5.359l-1.858-6.673z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFC226"
        fillRule="evenodd"
        d="M287.866 170.222l1.526 4.91c2.401-.626 4.083-2.021 5.361-4.427l-1.187-4.254c-1.445 2.122-3.365 3.13-5.7 3.771zm.93 6.813a.875.875 0 01-.835-.615l-2.041-6.562a.873.873 0 01.641-1.113c3.048-.697 5.084-1.731 6.461-4.96.147-.343.455-.549.863-.53.371.025.686.28.785.639l1.859 6.671a.87.87 0 01-.06.626c-1.667 3.336-4.054 5.189-7.514 5.83a1.056 1.056 0 01-.159.014z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M232.266 93.576c1.638 0 3.11.503 4.325.917.509.174.978.336 1.403.434.821.193 1.561-.39 1.916-.757 1.113-1.148 1.992-3.584 1.288-6.222a.875.875 0 01.534-1.043c1.206-.46 4.06.14 4.905.335a.877.877 0 01.475.29c.066.08 1.664 2.01 2.287 4.778.073.323.259.864.67.864h.018c.418-.014 1.113-.534 1.275-1.601.321-2.11-1.239-5.761-3.449-6.857-1.165-.577-2.471-1.155-5.376-2.248-1.339-.504-3.202.038-4.434.397l-.595.17c-1.645.44-3.63 1.284-5.55 2.101-.822.35-1.622.69-2.355.98l2.625 7.462h.038zm6.061 3.14c-.245 0-.487-.028-.728-.084-.478-.11-1.003-.289-1.574-.483-1.3-.443-2.784-.945-4.322-.796-.439.035-.777-.202-.91-.58l-3.124-8.88a.874.874 0 01.525-1.112c.912-.334 1.972-.786 3.08-1.257 1.976-.84 4.016-1.708 5.78-2.182l.56-.16c1.455-.423 3.656-1.065 5.539-.354 2.975 1.118 4.324 1.716 5.537 2.318 2.947 1.46 4.82 5.947 4.401 8.687-.259 1.71-1.524 3.038-2.941 3.09-.93.02-2.076-.538-2.457-2.23-.417-1.845-1.364-3.282-1.754-3.815-.987-.21-2.1-.371-2.844-.398.45 2.921-.584 5.52-1.926 6.908-.84.866-1.845 1.328-2.842 1.328zM269.276 145.001a17.725 17.725 0 01-1.459 2.086l1.636 15.996h-8.658c-2.262 0-4.291.919-5.395 2.254-1.104-1.335-3.132-2.254-5.395-2.254h-8.656l1.763-17.234c-.27-.407-.534-.827-.786-1.279-.455-.014-.872.31-.921.779l-1.895 18.522a.874.874 0 00.87.962h9.625c2.546 0 4.52 1.491 4.52 2.772a.875.875 0 001.75 0c0-1.281 1.974-2.772 4.52-2.772h9.625a.875.875 0 00.87-.962l-1.895-18.522a.828.828 0 00-.119-.348zM180.107 233.618h21.812l-5.607-13.307h-17.625l1.42 13.307zm23.129 1.75h-23.915a.874.874 0 01-.87-.782l-1.605-15.057a.872.872 0 01.87-.968h19.177c.351 0 .67.21.806.534l6.344 15.059a.876.876 0 01-.807 1.214z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M179.539 219.253h-4.849l-5.432-3.171s-7.912-5.687-9.369-7.729c-1.46-2.043-4.813-4.375-8.094-5.25a.582.582 0 00-.38.013c2.69.758 5.271 3.038 6.83 6.136a914.652 914.652 0 016.332 12.906l2.326 3.948.005-.003s.87 1.536.996 1.779c1.132 2.223.721 4.638-1.117 5.879a4.22 4.22 0 01-2.14.708l.017.023h18.85c0-5.25-1.094-12.031-3.975-15.239z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M180.19 218.669a.878.878 0 00-.651-.291h-4.614l-5.156-3.006c-2.168-1.56-8.043-5.95-9.168-7.529-1.685-2.361-5.296-4.709-8.582-5.586-.543-.145-1.054-.012-1.409.366-.07.073-.108.192-.163.289 1.934.271 3.866 1.366 5.466 3.032 1.438.94 2.612 2.006 3.262 2.918 1.499 2.098 8.75 7.339 9.642 7.974l5.43 3.173a.871.871 0 00.441.119h4.445c2.424 3.005 3.387 8.901 3.495 13.489h-15.669c-.062.046-.109.102-.172.144a4.234 4.234 0 01-3.279.626l.448.618a.872.872 0 00.708.362h18.848c.485 0 .875-.39.875-.875 0-5.388-1.101-12.376-4.197-15.823z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M179.134 211.229c-.289 0-1.481.861-2.44 2.977-.969 2.147-.81 3.62-.586 3.816h.011c.288.002 1.48-.857 2.439-2.975.97-2.145.812-3.62.588-3.818h-.012zm-3.05 8.529c-.3 0-.541-.071-.711-.148-1.659-.751-1.391-3.649-.271-6.125.551-1.22 1.277-2.289 2.042-3.009 1.295-1.214 2.27-1.044 2.737-.833 1.656.749 1.39 3.649.271 6.125-.551 1.222-1.277 2.288-2.044 3.007-.819.77-1.508.983-2.024.983z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M175.383 219.909a.876.876 0 01-.584-.222c-2.135-1.914-3.672-5.668-2.001-9.388a.878.878 0 011.159-.44.873.873 0 01.439 1.155c-1.311 2.921-.105 5.868 1.572 7.368a.875.875 0 01-.585 1.527zM149.531 203.711c-1.059 0-2.06.306-2.947.91-1.246.85-1.972 2.018-2.154 3.467-.303 2.399.947 5.451 3.341 8.167 3.312 3.759 8.009 8.312 9.548 9.788l6.142-4.185a888.145 888.145 0 00-5.997-12.213c-1.454-2.889-3.917-5.087-6.424-5.735a6.003 6.003 0 00-1.509-.199zm7.695 24.328a.873.873 0 01-.6-.237c-.06-.058-6.124-5.8-10.168-10.388-2.761-3.135-4.133-6.614-3.764-9.545.243-1.941 1.249-3.565 2.907-4.694 1.725-1.177 3.757-1.506 5.876-.959 3.028.783 5.85 3.266 7.55 6.642 2.849 5.656 6.303 12.848 6.34 12.922a.878.878 0 01-.298 1.101l-7.35 5.006a.863.863 0 01-.493.152zM160.978 231.197l.654.958c1.038 1.524 3.131 1.918 4.664.882 1.447-.979 1.78-2.89.826-4.757a45.308 45.308 0 00-.508-.919l-5.636 3.836zm3.435 4.167a5.102 5.102 0 01-4.228-2.224l-1.146-1.678a.878.878 0 01-.139-.657.88.88 0 01.368-.561l7.147-4.865a.873.873 0 011.255.294s.885 1.566 1.009 1.813c1.355 2.649.777 5.528-1.401 7a5.11 5.11 0 01-2.865.878z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M158.435 227.401l1.566 2.347 5.737-3.898-1.441-2.443-5.862 3.994zm1.326 4.441a.884.884 0 01-.728-.39l-2.534-3.801a.875.875 0 01.235-1.209l7.352-5.007a.872.872 0 011.244.278l2.331 3.957a.873.873 0 01-.261 1.167l-7.147 4.855a.887.887 0 01-.492.15zM319.717 233.618h21.813l1.416-13.307h-17.622l-5.607 13.307zm22.599 1.75h-23.917a.876.876 0 01-.807-1.214l6.344-15.059a.875.875 0 01.807-.534h19.176a.876.876 0 01.87.966l-1.603 15.059a.874.874 0 01-.87.782z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M369.844 203.103c-3.282.875-6.636 3.207-8.094 5.25-1.459 2.042-9.37 7.729-9.37 7.729l-5.433 3.171h-4.848c-2.88 3.21-3.974 9.991-3.974 15.239h18.849l.014-.023a4.21 4.21 0 01-2.138-.708c-1.836-1.241-2.251-3.656-1.115-5.879.122-.243.994-1.779.994-1.779l.003.003 2.328-3.948c0-.002 3.453-7.192 6.33-12.906 1.561-3.098 4.144-5.378 6.833-6.136a.58.58 0 00-.379-.013z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M371.026 202.622c-.356-.376-.867-.509-1.411-.364-3.285.875-6.891 3.224-8.58 5.586-1.127 1.577-6.998 5.97-9.097 7.482l-5.23 3.052h-4.61a.877.877 0 00-.651.29c-3.097 3.449-4.2 10.435-4.2 15.825 0 .483.392.875.875.875h18.851c.28 0 .544-.134.709-.362l.444-.618a4.226 4.226 0 01-3.277-.628c-.063-.042-.112-.096-.172-.142h-15.669c.108-4.588 1.069-10.484 3.493-13.49h4.445a.873.873 0 00.441-.119l5.503-3.217c.821-.591 8.071-5.833 9.569-7.931.651-.91 1.826-1.977 3.262-2.915 1.602-1.666 3.532-2.762 5.467-3.033-.054-.096-.092-.217-.162-.291z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M342.48 211.242l-.019.001c-.194.184-.353 1.656.618 3.805.975 2.154 2.221 2.984 2.478 2.961.194-.184.353-1.656-.618-3.803-.952-2.102-2.131-2.964-2.459-2.964zm3.072 8.517c-.517 0-1.208-.214-2.023-.984-.767-.719-1.491-1.786-2.043-3.006-.554-1.22-.876-2.471-.911-3.521-.06-1.775.714-2.392 1.181-2.604.465-.212 1.442-.382 2.735.833.765.719 1.489 1.787 2.041 3.008.554 1.22.876 2.469.911 3.519.06 1.775-.714 2.394-1.181 2.604a1.69 1.69 0 01-.71.151z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M346.254 219.91a.875.875 0 01-.585-1.526c1.677-1.504 2.883-4.451 1.572-7.37a.873.873 0 01.439-1.155.878.878 0 011.159.44c1.669 3.718.133 7.474-2.001 9.387a.873.873 0 01-.584.224zM358.174 221.859l6.144 4.186c1.54-1.477 6.237-6.033 9.548-9.79 2.394-2.716 3.644-5.77 3.341-8.167-.184-1.449-.908-2.616-2.154-3.467-1.3-.882-2.839-1.13-4.456-.71-2.509.647-4.971 2.845-6.426 5.734a911.438 911.438 0 00-5.997 12.214zm6.235 6.181a.855.855 0 01-.491-.153l-7.352-5.006a.873.873 0 01-.296-1.101c.035-.074 3.491-7.266 6.339-12.922 1.699-3.376 4.522-5.859 7.549-6.643 2.121-.546 4.155-.215 5.878.961 1.658 1.128 2.664 2.751 2.907 4.693.369 2.931-1.003 6.41-3.764 9.543-4.044 4.59-10.108 10.332-10.168 10.39a.875.875 0 01-.602.238zM355.023 227.362a43.45 43.45 0 00-.509.917c-.954 1.867-.621 3.78.826 4.758 1.536 1.039 3.629.64 4.665-.882l.655-.957-5.637-3.836zm2.202 8.002a5.097 5.097 0 01-2.867-.878c-2.179-1.474-2.754-4.352-1.403-7.002.126-.245 1.013-1.813 1.013-1.813a.873.873 0 011.253-.292l7.147 4.865a.882.882 0 01.231 1.218l-1.148 1.678a5.101 5.101 0 01-4.226 2.224z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M355.9 225.851l5.736 3.897 1.567-2.347-5.865-3.993-1.438 2.443zm5.976 5.992a.879.879 0 01-.492-.152l-7.147-4.853a.878.878 0 01-.262-1.169l2.329-3.955a.88.88 0 01.564-.41.86.86 0 01.682.132l7.354 5.006c.395.27.502.809.234 1.208l-2.534 3.803a.876.876 0 01-.728.39zM282.755 201.839h-.004l-53.421-.184a.874.874 0 01.004-1.75h.003l52.541.18c-.035-9.787-.616-25.334-1.572-35.316a.872.872 0 01.788-.954.864.864 0 01.955.787c.989 10.339 1.581 26.607 1.581 36.362a.872.872 0 01-.258.619.869.869 0 01-.617.256z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M284.203 209.312s-.657-2.187-1.448-8.347l-53.422-.184-1.078 8.537c-14.49 2.717-24.552 6.217-37.422 7.675 2.991 4.424 4.011 10.839 4.156 17.5h128.807c.146-6.661 1.168-13.076 4.157-17.5-14.511-.826-27.782-5.493-43.75-7.681z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M195.841 233.617h127.104c.228-6.833 1.379-12.043 3.504-15.846-7.84-.583-15.278-2.214-23.126-3.934-6.12-1.34-12.446-2.728-19.238-3.657a.871.871 0 01-.719-.616c-.028-.088-.634-2.141-1.379-7.727l-51.883-.18-.98 7.77a.875.875 0 01-.708.749c-5.837 1.096-10.92 2.31-15.838 3.484-6.736 1.61-13.121 3.135-20.279 4.038 2.149 3.809 3.313 9.04 3.542 15.919zm127.955 1.75H194.991a.874.874 0 01-.875-.855c-.168-7.721-1.479-13.29-4.006-17.03a.866.866 0 01-.07-.855.872.872 0 01.695-.504c7.616-.863 14.33-2.466 21.437-4.164 4.762-1.137 9.679-2.311 15.295-3.384l.999-7.905c.055-.439.413-.747.872-.765l53.42.184c.44.002.809.329.865.765.558 4.35 1.052 6.704 1.283 7.672 6.637.938 12.813 2.292 18.791 3.603 8.264 1.81 16.067 3.521 24.306 3.99a.875.875 0 01.674 1.363c-2.527 3.74-3.838 9.312-4.006 17.03a.874.874 0 01-.875.855zM282.442 197.167h-51.249a.875.875 0 110-1.75h51.249a.875.875 0 010 1.75z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M293.325 159.105c-4.501-10.959-12.007-17.435-21.861-18.984a31.629 31.629 0 01-.614 1.671c9.397 1.37 16.558 7.515 20.854 17.978 1.981 4.819 10.635 35.149 12.408 54.871a.873.873 0 00.95.793.876.876 0 00.793-.95c-1.706-18.988-10.12-49.513-12.53-55.379zM293.39 215.437a.872.872 0 01-.847-.658c-2.914-11.433-8.137-24.073-10.633-28.439a.877.877 0 011.523-.868c2.542 4.45 7.854 17.293 10.804 28.873a.872.872 0 01-.847 1.092z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M255.891 160.84c-5.04 0-8.871-4.177-9.072-9.791-2.711-1.955-6.974-6.344-8.225-15.094-2.109-.619-3.398-3.713-3.887-6.401-.388-2.134-.028-4.057.989-5.275.7-.841 1.666-1.286 2.788-1.286a.875.875 0 010 1.75c-.604 0-1.076.215-1.444.658-.663.795-.898 2.265-.611 3.84.51 2.808 1.775 5.018 2.897 5.074a.945.945 0 01.463.102c.251.135.42.383.455.665 1.094 8.93 5.439 13.094 7.933 14.782.24.161.385.434.385.725 0 4.846 3.15 8.501 7.329 8.501 3.969 0 6.962-3.655 6.962-8.501 0-.31.161-.597.428-.755 4.465-2.646 7.072-7.162 8.714-15.106.085-.414.404-.699.876-.698.604-.105 2.114-2.716 2.603-4.83.327-1.41.295-3.325-.399-4.095-.189-.208-.532-.474-1.334-.369a.875.875 0 01-.222-1.736c1.487-.192 2.373.401 2.854.931 1.636 1.809.896 5.273.807 5.663-.212.915-1.593 5.148-3.586 6.027-1.708 7.903-4.429 12.579-9 15.463-.206 5.596-3.878 9.756-8.703 9.756z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M267.862 116.89l-.021-.084c-4.137-5.538-8.51-9.003-16.888-8.459-7.04.457-9.297 3.937-10.976 7.45l-.007-.046c-.334 2.324-.281 4.746-.281 4.746 4.866 2.403 9.745.957 13.233-1.601 5.313-3.897 8.022 3.645 13.344.182.108 4.048 2.733 6.562 7.366 4.79 0 0 .437-2.338.437-5.208 0 0-3.757-1.005-6.207-1.77z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M242.887 111.436c-.055.054-.107.11-.161.166.05-.054.11-.112.161-.166zM273.973 115.176c.059 1.115.096 2.268.096 3.483 0 0-.366-.096-.906-.245.007.08.031.17.031.245 0 1.995-.22 3.741-.345 4.559-1.624.526-2.996.462-3.976-.196-1.067-.713-1.683-2.121-1.732-3.968a.88.88 0 00-.875-.85.87.87 0 00-.478.142c-2.201 1.429-3.752.687-5.714-.259-2.128-1.026-4.539-2.19-7.67.103-1.808 1.326-6.506 4.09-11.842 1.748.007-1.328.108-4.788.913-6.636.191-.441.597-.98 1.124-1.566-1.155 1.216-1.937 2.634-2.622 4.06 0 0-.18-.922-.444-2.238-.8 2.754-.725 6.769-.719 6.959.007.326.196.62.488.765 4.501 2.223 9.651 1.612 14.138-1.68 2.275-1.671 3.866-.906 5.873.061 1.78.856 3.761 1.81 6.209.798.308 1.782 1.129 3.182 2.378 4.017.66.439 1.623.843 2.942.843.873 0 1.902-.177 3.101-.635a.874.874 0 00.548-.656c.019-.1.453-2.452.453-5.371 0-1.038-.416-2.286-.971-3.483z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M247.687 150.588c1.382.903 3.27 1.349 4.464 1.349l-4.464-1.349z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M252.151 152.813c-1.278 0-3.373-.467-4.942-1.493a.876.876 0 01-.256-1.211.878.878 0 011.213-.253c1.214.792 2.924 1.207 3.985 1.207a.875.875 0 010 1.75zM257.857 126.654a1.315 1.315 0 002.631 0 1.315 1.315 0 00-2.631 0zM247.248 126.654a1.315 1.315 0 102.63-.001 1.315 1.315 0 00-2.63.001zM253.626 133.064c-.94 0-1.864-.476-2.475-1.276-.598-.782-.799-1.75-.551-2.656.322-1.183 1.04-1.79 1.675-2.326.313-.266.609-.516.847-.829.612-.809.759-1.647.455-2.634a.877.877 0 011.675-.513c.467 1.528.22 2.942-.735 4.204-.354.467-.758.808-1.113 1.109-.543.459-.937.791-1.117 1.451-.101.374-.01.787.256 1.134.276.362.691.586 1.083.586a.875.875 0 010 1.75zM253.796 135.593a1.387 1.387 0 10.001 2.773 1.387 1.387 0 00-.001-2.773zm0 4.523a3.14 3.14 0 01-3.136-3.137 3.14 3.14 0 013.136-3.136 3.142 3.142 0 013.138 3.136 3.142 3.142 0 01-3.138 3.137zM255.401 183.699a.875.875 0 01-.875-.875v-15.219a.874.874 0 111.75 0v15.219a.875.875 0 01-.875.875zM265.756 182.297l4.958 3.221 4.957-3.221v-10.609h-9.915v10.609zm4.958 5.141a.863.863 0 01-.476-.142l-5.833-3.792a.873.873 0 01-.399-.733v-11.958c0-.485.392-.875.875-.875h11.665c.483 0 .875.39.875.875v11.958c0 .296-.15.572-.399.733l-5.832 3.792a.868.868 0 01-.476.142z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M272.756 175.406h-7.875a.875.875 0 010-1.75h7.875a.875.875 0 010 1.75zM236.895 182.297l4.957 3.221 4.96-3.221v-10.609h-9.917v10.609zm4.957 5.141a.868.868 0 01-.476-.142l-5.832-3.792a.873.873 0 01-.399-.733v-11.958c0-.485.392-.875.875-.875h11.667c.483 0 .875.39.875.875v11.958a.875.875 0 01-.399.733l-5.835 3.792a.863.863 0 01-.476.142z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M243.895 175.406h-7.875a.875.875 0 010-1.75h7.875a.875.875 0 010 1.75z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#88D2D3"
        d="M273 107c-2.8-6.8-6.833-7.167-8.5-6.5 2.4 2.8 3 12.167 3 16.5l6 1.5c.167-2.333.7-6.7-.5-11.5z"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M274.068 119.534a.83.83 0 01-.226-.03c-.036-.008-3.802-1.016-6.244-1.779a.875.875 0 11.524-1.67c1.592.497 3.766 1.103 5.062 1.458-.178-11.604-3.141-16.853-9.535-16.973a.874.874 0 01.017-1.75h.016c10.094.192 11.261 11.432 11.261 19.869a.873.873 0 01-.875.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#EDBDBD"
        fillRule="evenodd"
        d="M252.293 108.516c6.775 0 11.037 2.909 14.707 7.484a617.264 617.264 0 00-.931-8.458c-.446-3.754-2.154-6.929-4.937-9.187-3.593-2.91-8.57-3.977-14.014-3.005-3.356.6-5.956 2.234-7.521 4.721-1.468 2.331-1.946 5.355-1.342 8.513.396 2.068.872 4.641 1.221 6.551 1.827-3.221 4.733-6.108 11.008-6.553a25.65 25.65 0 011.809-.066z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M252.628 107.417c6.483 0 10.563 2.555 14.075 6.573a496.655 496.655 0 00-.891-7.429c-.427-3.297-2.061-6.087-4.725-8.07-3.439-2.556-8.202-3.492-13.412-2.638-3.211.526-5.7 1.961-7.198 4.145-1.405 2.048-1.862 4.704-1.284 7.478.38 1.817.835 4.076 1.169 5.754 1.748-2.83 4.529-5.365 10.535-5.756a27.174 27.174 0 011.731-.057zm15.277 10.349a.879.879 0 01-.702-.353c-4.046-5.445-8.195-8.721-16.194-8.192-6.596.427-8.608 3.533-10.239 6.953a.901.901 0 01-.9.491.874.874 0 01-.749-.7s-.948-4.807-1.64-8.132c-.677-3.246-.126-6.38 1.551-8.825 1.776-2.587 4.665-4.275 8.36-4.881 5.689-.936 10.92.119 14.74 2.961 3.054 2.272 4.926 5.469 5.416 9.247.569 4.384 1.229 10.462 1.229 10.462a.875.875 0 01-.872.969zM242.489 122.338a.87.87 0 01-.213-.027c-3.971-.997-6.895-3.059-8.943-6.301a.875.875 0 111.479-.935c1.82 2.884 4.328 4.645 7.891 5.539a.876.876 0 01-.214 1.724z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M242.548 119.921c-1.885 0-3.629-.582-5.012-1.741a.874.874 0 111.124-1.34c2.072 1.736 5.269 1.776 8.349.106a.874.874 0 11.833 1.539c-1.768.96-3.588 1.436-5.294 1.436z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M239.919 138.26c-3.578-1.109-16.005-7.887-21.035-15.162 2.216-6.786 5.067-12.657 8.952-18.443 1.552-2.315 1.967-4.753 1.153-6.464l3.234-3.075a.878.878 0 00.222-.924l-3.125-8.88a.872.872 0 00-1.134-.528c-2.674 1.01-5.529 2.947-8.04 5.456-7.579 7.58-13.624 15.787-17.019 23.111-1.984 4.282-4.59 12.672.265 20.725 6.889 11.429 15.718 21.012 24.267 26.355l2.07 8.281c-.152 3.231-.269 7.221-.387 11.302-.12 4.128-.245 8.351-.407 11.975a.875.875 0 00.833.915h.04a.875.875 0 00.873-.835c.166-3.634.291-7.866.41-12.003.119-4.126.238-8.157.392-11.399a.832.832 0 00-.025-.254l-2.187-8.75a.874.874 0 00-.392-.534c-8.411-5.162-17.156-14.623-23.991-25.956-4.436-7.36-2.013-15.118-.172-19.085 3.31-7.144 9.23-15.171 16.667-22.61 2.078-2.076 4.388-3.724 6.593-4.715l2.63 7.476-3.336 3.17a.875.875 0 00-.084 1.174c.975 1.24.3 3.454-.803 5.096-4.056 6.041-7.009 12.169-9.294 19.288a.87.87 0 00.101.747c5.067 7.711 18.08 14.973 22.281 16.242a.86.86 0 00.858-.222c-.142-.485-.291-.957-.41-1.474zM371.67 110.972a.875.875 0 01-.875-.875v-3.915a.875.875 0 011.75 0v3.915a.875.875 0 01-.875.875zM371.67 119.192a.875.875 0 01-.875-.875v-3.914a.875.875 0 011.75 0v3.914a.875.875 0 01-.875.875zM371.67 127.414a.875.875 0 01-.875-.875v-3.915a.875.875 0 011.75 0v3.915a.875.875 0 01-.875.875zM376.172 110.972a.875.875 0 01-.875-.875v-3.915a.875.875 0 011.75 0v3.915a.875.875 0 01-.875.875zM376.172 119.192a.875.875 0 01-.875-.875v-3.914a.875.875 0 011.75 0v3.914a.875.875 0 01-.875.875zM380.673 110.972a.875.875 0 01-.875-.875v-3.915a.875.875 0 011.75 0v3.915a.875.875 0 01-.875.875zM324.646 91.966a.875.875 0 01-.875-.875v-3.915a.875.875 0 011.75 0v3.915a.875.875 0 01-.875.875zM324.646 100.186a.875.875 0 01-.875-.875v-3.915a.875.875 0 011.75 0v3.915a.875.875 0 01-.875.875zM320.145 91.966a.875.875 0 01-.875-.875v-3.915a.875.875 0 011.75 0v3.915a.875.875 0 01-.875.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M396.691 134.132a.875.875 0 01-.875-.875v-32.984h-28.523v26.266c0 .483-.39.875-.875.875h-11.697a.874.874 0 01-.875-.875V57.596l-13.647-38.922-12.789 38.913V81.13a.875.875 0 01-.875.875h-20.847a.876.876 0 110-1.75h19.972V57.447c0-.092.016-.185.046-.273l13.636-41.494a.87.87 0 01.821-.6c.355.038.71.23.834.584l14.548 41.495c.033.092.051.19.051.288v68.217h9.947V99.398c0-.484.392-.875.875-.875h30.273c.483 0 .875.39.875.875v33.859a.875.875 0 01-.875.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M340.172 16.83a.875.875 0 01-.875-.876V10.67a.875.875 0 011.75 0v5.285a.875.875 0 01-.875.875zM119.813 204.424v6.695c7.079 6.575 10.91 9.949 8.939 14.222-1.13 2.452-4.959 3.108-8.939 3.108v6.045c6.824 0 12.555-1.468 14.042-6.372 2.048-6.746-1.421-13.636-14.042-23.698z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M120.688 229.314v4.296c7.165-.14 11.2-2.021 12.331-5.741 1.935-6.381-1.761-12.898-12.331-21.609v4.476l.333.31c6.904 6.408 10.706 9.936 8.526 14.661-1.061 2.3-3.962 3.485-8.859 3.607zm-.875 6.053a.874.874 0 01-.875-.875v-6.042c0-.483.392-.875.875-.875 4.545 0 7.361-.9 8.145-2.601 1.585-3.435-1.174-6.191-8.127-12.645l-.613-.569a.876.876 0 01-.28-.641v-6.695a.875.875 0 011.421-.684c12.31 9.814 16.597 17.183 14.335 24.636-1.428 4.704-6.297 6.991-14.881 6.991zM107.01 228.378c-4.342-.14-8.967-.356-13.654-.356-18.772 0-27.872-13.49-23.43-25.938 3.11-8.72 17.008-13.556 37.084.152v-6.125c-25.618-16.333-37.975-8.577-43.211 2.812-7.536 16.387 3.888 35.69 26.47 35.69h16.741v-6.235z"
        clipRule="evenodd"
      ></path>
      <mask id="mask1" width="48" height="51" x="60" y="185" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M60.63 185.884h47.256v49.603H60.63v-49.603z"></path>
      </mask>
      <g mask="url(#mask1)">
        <path
          fill="#394A58"
          fillRule="evenodd"
          d="M81.758 187.63c-1.963 0-3.807.254-5.53.76-5.081 1.496-8.998 5.162-11.635 10.899-3.392 7.378-2.856 15.778 1.431 22.47 4.88 7.612 13.717 11.979 24.245 11.979h15.867v-4.515l-2.2-.076c-3.435-.122-6.988-.25-10.579-.25-9.59 0-17.608-3.572-21.997-9.8-3.56-5.049-4.382-11.356-2.257-17.307 1.358-3.81 4.469-6.677 8.76-8.073 4.47-1.453 13.984-2.42 28.273 6.884v-4.009c-9.448-5.962-17.619-8.962-24.377-8.962zm25.253 47.858H90.269c-11.137 0-20.512-4.661-25.718-12.784-4.613-7.196-5.19-16.224-1.547-24.147 2.819-6.128 7.218-10.225 12.73-11.845 8.249-2.426 18.925.488 31.746 8.662a.87.87 0 01.406.737v6.125c0 .325-.18.623-.467.773a.874.874 0 01-.903-.05c-14.433-9.856-23.81-8.98-28.11-7.578-3.765 1.225-6.484 3.708-7.657 6.997-1.93 5.407-1.186 11.135 2.04 15.711 4.059 5.756 11.556 9.058 20.569 9.058 3.62 0 7.189.128 10.64.25l3.041.107a.873.873 0 01.847.873v6.236a.875.875 0 01-.875.875z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M94.798 207.349c-2.31 0-4.07.657-4.694 1.974-.565 1.196-.086 2.907 1.313 4.701 1.552 1.988 4.01 3.843 6.924 5.222 5.73 2.712 11.633 2.749 12.896.084.565-1.194.086-2.905-1.312-4.699-1.553-1.99-4.011-3.843-6.925-5.222-2.898-1.372-5.838-2.06-8.202-2.06zm11.66 15.696c-2.613 0-5.724-.731-8.864-2.217-3.155-1.495-5.838-3.528-7.556-5.728-1.848-2.368-2.387-4.686-1.516-6.524 1.73-3.645 8.416-3.972 15.225-.751 3.155 1.495 5.838 3.53 7.557 5.728 1.848 2.37 2.387 4.687 1.515 6.526-.931 1.965-3.306 2.966-6.361 2.966z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F1F1F2"
        fillRule="evenodd"
        d="M106.962 234.794h12.773V5.511c-7.644 0-12.773 4.882-12.773 11.663v217.62z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M119.813 220.757a.875.875 0 01-.875-.875V5.507a.875.875 0 011.75 0v214.375a.875.875 0 01-.875.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M119.814 235.486h-12.803a.874.874 0 01-.875-.875V17.174a.874.874 0 111.75 0v216.562h11.053v-7.255a.875.875 0 011.75 0v8.13a.875.875 0 01-.875.875zM33.997 137.45h-.019l-5.25-.108a.875.875 0 01.02-1.75h.018l4.701.098 4.672-9.139a.876.876 0 011.56.798l-4.923 9.625a.878.878 0 01-.779.476zM12.659 182.916a.874.874 0 01-.788-1.254l4.465-9.244-2.92-3.687a.878.878 0 01.144-1.23.878.878 0 011.23.143l3.257 4.12a.877.877 0 01.102.922l-4.703 9.735a.872.872 0 01-.787.495zM150.372 143.466a.874.874 0 01-.812-1.202c.181-.443 17.912-44.349 19.31-48.467 1.55-4.567.059-8.312-4.093-10.277-3.656-1.736-8.869-1.288-11.323 2.947-2.345 4.044-27.02 45.221-27.268 45.636a.875.875 0 11-1.502-.899c.249-.415 24.918-41.582 27.256-45.616 2.979-5.138 9.221-5.715 13.586-3.652 4.973 2.355 6.842 7 5.003 12.423-1.414 4.165-18.613 46.748-19.345 48.561a.879.879 0 01-.812.546z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M150.915 133.477a14.584 14.584 0 00-7.544-8.33c-7.313-3.465-16.091-.331-19.556 6.988-3.465 7.318-.329 16.091 6.989 19.556a14.579 14.579 0 006.26 1.41c.94 0 1.865-.122 2.781-.299l-.93-1.587c-2.43.35-4.984.021-7.362-1.106-6.447-3.052-9.208-10.78-6.156-17.225 2.208-4.664 6.863-7.399 11.709-7.399 1.853 0 3.734.4 5.518 1.244a12.848 12.848 0 016.644 7.338 12.853 12.853 0 01-.491 9.887 12.843 12.843 0 01-5.747 5.931l.803 1.531c2.754-1.424 5.099-3.699 6.527-6.713a14.583 14.583 0 00.555-11.226zM74.648 97.739l7.494 4.74 1.73-3.302-6.81-4.438-2.414 3zm7.83 6.863a.873.873 0 01-.47-.135l-9.125-5.775a.869.869 0 01-.394-.595.868.868 0 01.18-.691l3.526-4.382a.875.875 0 011.16-.184l8.138 5.304c.378.247.506.74.298 1.14l-2.538 4.849a.876.876 0 01-.775.469zM33.22 153.277a.863.863 0 00-.646-.213c-.235.019-4.98.406-8.29 1.142-.33.693-.656 1.385-.978 2.064 2.516-.804 7.633-1.304 9.042-1.43l8.708 7.614a.874.874 0 001.234-.082.876.876 0 00-.083-1.236l-8.988-7.859z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M45.666 157.297c-.042-.039-4.279-3.964-8.557-7.378-2.095-1.675-5.44-1.915-10.427-.779l-.989 2.089a2.79 2.79 0 01.508-.187c6.482-1.629 8.708-.64 9.817.243 4.225 3.374 8.416 7.256 8.458 7.294a.873.873 0 001.236-.045.873.873 0 00-.046-1.237zM190.41 104.23c-8.034 16.968-28.301 24.21-45.269 16.177-16.968-8.034-24.21-28.302-16.175-45.27 8.032-16.968 28.301-24.21 45.269-16.176 16.968 8.035 24.209 28.301 16.175 45.269zm-67.676-32.044c-9.662 20.41-.952 44.788 19.456 54.451 20.409 9.664 44.786.952 54.452-19.456 9.661-20.409.95-44.788-19.459-54.45-20.408-9.663-44.784-.952-54.449 19.455z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M159.739 56.568c-12.411 0-24.329 7.002-29.983 18.942-3.785 7.996-4.232 16.988-1.257 25.318 2.979 8.33 9.022 15.002 17.016 18.788 16.5 7.813 36.289.747 44.103-15.761 3.785-7.994 4.232-16.985 1.257-25.315-2.977-8.33-9.02-15.003-17.016-18.788a32.908 32.908 0 00-14.12-3.184zm-.107 67.981a34.658 34.658 0 01-14.866-3.351c-8.418-3.987-14.779-11.011-17.913-19.782-3.135-8.77-2.664-18.235 1.321-26.655 8.227-17.375 29.053-24.818 46.434-16.591 8.42 3.986 14.779 11.01 17.913 19.782 3.133 8.769 2.666 18.235-1.319 26.652-5.954 12.572-18.505 19.945-31.57 19.945zm-36.106-51.987c-4.575 9.658-5.112 20.52-1.517 30.584 3.596 10.066 10.897 18.127 20.557 22.7 19.941 9.443 43.843.899 53.284-19.04 4.573-9.66 5.112-20.523 1.515-30.587-3.596-10.062-10.897-18.125-20.555-22.697-19.943-9.442-43.845-.9-53.284 19.04zm36.097 58.88c-5.981 0-12.05-1.29-17.806-4.014-10.082-4.774-17.701-13.187-21.455-23.692-3.754-10.505-3.192-21.842 1.58-31.923 9.856-20.811 34.806-29.728 55.617-19.873 10.08 4.774 17.701 13.188 21.453 23.691 3.754 10.505 3.194 21.842-1.58 31.924-7.13 15.057-22.162 23.887-37.809 23.887z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#EDBDBD"
        fillRule="evenodd"
        d="M62.737 159.888l-12.223 2.401 7.252 8.974 4.971-11.375zM138.792 135.328l-33.787-23.718 54.84-20.745-21.053 44.463zm18.703-49.207c-5.315 1.678-53.379 19.269-58.121 21.69L83.349 96.516l-3.369 7.117 4.986 3.52 3.864 2.861 39.629 28.569c-.06.127-.347.729-1.476 3.118-1.624 3.427-5.239 6.634-9.234 7.406-4.746.914-27.566 5.392-45.32 8.878l4.522 2.14-4.68 9.882c15.008-2.728 37.893-6.818 43.71-7.456 8.85-.973 17.896-5.082 21.639-12.991.042-.084.1-.204.147-.306l-3.948-6.737c-1.969-4.157 2.261-5.614 3.937-2.625.294.525 1.187 2.205 2.351 4.418 6.595-13.926 22.839-48.237 24.505-51.754 2.06-4.349-2.14-8.008-7.117-6.435z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M164.756 86.952c-1.68-1.925-4.639-2.58-7.527-1.664-5.271 1.664-50.832 18.321-57.774 21.513L83.854 95.802a.875.875 0 00-1.297.34l-3.369 7.117a.873.873 0 00.287 1.09l4.986 3.519a.874.874 0 101.008-1.429l-4.386-3.096 2.611-5.513 15.173 10.696c.266.188.61.212.901.065 4.424-2.257 52.089-19.771 57.988-21.635 2.226-.704 4.457-.256 5.681 1.146.953 1.094 1.093 2.58.383 4.081l-24.224 51.16 1.021 1.937 24.785-52.348c1.006-2.126.766-4.363-.646-5.98zM57.373 172.166l.79-1.808-6.058-7.49c1.892-.371 5.555-1.092 10.206-2.004l.854-1.951a38761.25 38761.25 0 00-12.822 2.518.874.874 0 00-.51 1.409l7.54 9.326z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M127.355 138.867l-1.164 2.459c-1.615 3.409-5.155 6.254-8.61 6.921-4.821.931-28.565 5.589-46.483 9.107l2.66 1.262c17.589-3.453 39.543-7.76 44.154-8.651 4.03-.777 7.994-3.948 9.861-7.89.672-1.42 1.101-2.328 1.341-2.926a.996.996 0 00.145-.216l-.053-.028c.3-.836.107-.925-.357-1.146-.029-.014-.059-.024-.087-.035l-39.422-28.42a.874.874 0 10-1.022 1.419l39.037 28.144zM137.23 148.34l-.401.847c-3.188 6.732-11.018 11.403-20.944 12.493-5.81.639-28.13 4.624-43.151 7.354l-.924 1.948c14.992-2.727 38.426-6.92 44.266-7.562 10.425-1.146 18.704-6.097 22.22-13.262l-1.066-1.818zM138.445 134.016l-31.584-22.172 51.265-19.392-19.681 41.564zm22.043-43.743a.878.878 0 00-.952-.226l-54.84 20.745a.872.872 0 00-.558.709c-.042.322.1.64.364.826l33.787 23.717a.876.876 0 001.294-.341l21.054-44.462a.877.877 0 00-.149-.968z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M134.594 142.11l12.164 20.765 1.477-1.135c-1.804-3.462-10.221-19.6-11.242-21.419-.603-1.078-1.58-1.321-2.194-1.006-.74.382-.821 1.477-.205 2.795zm11.912 22.939a.887.887 0 01-.756-.432l-12.687-21.657-.037-.068c-1.216-2.571-.39-4.429.97-5.133 1.335-.691 3.379-.33 4.523 1.705 1.152 2.053 11.181 21.299 11.606 22.118a.872.872 0 01-.243 1.097l-2.844 2.188a.87.87 0 01-.532.182z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M154.217 160.646l-11.397 7.618-2.067-3.096 11.394-7.617 2.07 3.095z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M141.966 165.409l1.095 1.64 9.944-6.643-1.097-1.641-9.942 6.644zm.854 3.729a.88.88 0 01-.728-.388l-2.067-3.096a.874.874 0 01.24-1.213l11.396-7.617a.873.873 0 011.214.24l2.071 3.095a.88.88 0 01-.242 1.215l-11.396 7.617a.869.869 0 01-.488.147z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#EDBDBD"
        fillRule="evenodd"
        d="M58.559 169.449c-7.494-6.391-13.488-11.511-13.488-11.511l-3.44 3.857s6.804 5.559 14.924 12.237l2.004-4.583zM102.831 212.246c-3.696-6.281-9.107-12.996-17.162-19.756-3.307-2.776-10.075-8.528-17.15-14.553l-2.032 4.284c7.937 6.564 15.096 12.551 16.72 14.105 7.658 7.338 11.926 11.616 14.98 16.502 2.298 3.677 6.501 2.573 4.644-.582z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M56.191 174.866l.73-1.668a6187.617 6187.617 0 00-14.03-11.501l2.263-2.537c1.766 1.508 6.92 5.909 13.04 11.126l.73-1.675a17929.79 17929.79 0 01-13.284-11.338.88.88 0 00-1.223.082l-3.439 3.857a.876.876 0 00.098 1.26c.161.132 7.007 5.726 15.115 12.394zM103.585 211.802c-4.183-7.107-10.022-13.828-17.355-19.981-3.316-2.785-10.174-8.614-17.325-14.704l-.777 1.638c7.025 5.982 13.713 11.666 16.977 14.404 7.182 6.027 12.892 12.597 16.973 19.53.154.263.616 1.141.109 1.448-.524.318-2.071.126-3.259-1.773-3.181-5.089-7.833-9.693-15.116-16.671-1.66-1.589-8.925-7.663-16.937-14.288l-.773 1.632c7.855 6.498 14.91 12.396 16.5 13.92 7.85 7.52 11.872 11.583 14.842 16.336 1.164 1.862 2.826 2.716 4.212 2.716.538 0 1.031-.128 1.441-.376.932-.565 1.618-1.909.488-3.831z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#88D2D3"
        fillRule="evenodd"
        d="M163.904 59.263l-25.496 53.845a3.17 3.17 0 01-4.221 1.509l-31.02-14.688a3.17 3.17 0 01-1.509-4.22l25.496-53.847a3.17 3.17 0 014.221-1.508l31.02 14.688a3.17 3.17 0 011.509 4.22z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M130.02 40.922a2.291 2.291 0 00-2.075 1.315l-25.496 53.845a2.3 2.3 0 001.092 3.058l31.019 14.687a2.3 2.3 0 003.057-1.093l25.496-53.846a2.298 2.298 0 00-1.092-3.056L131 41.142a2.29 2.29 0 00-.98-.22zm5.52 74.878a4.03 4.03 0 01-1.727-.392l-31.021-14.688a4.05 4.05 0 01-1.925-5.387l25.496-53.845a4.023 4.023 0 012.294-2.08 4.026 4.026 0 013.092.155L162.77 54.25a4.05 4.05 0 011.925 5.387l-25.496 53.846a4.01 4.01 0 01-2.296 2.077c-.444.159-.903.24-1.363.24z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#88D2D3"
        fillRule="evenodd"
        d="M108.181 102.053l7.091-2.534s9.219-17.461 12.131-25.177c4.375-11.594 3.062-28.219-3.282-36.531 6.563-5.032 13.782.218 15.75 6.562"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M108.181 102.928a.875.875 0 01-.294-1.699l6.769-2.42c1.23-2.346 9.278-17.752 11.929-24.777 4.318-11.445 2.872-27.79-3.158-35.691a.874.874 0 01.162-1.225c3.485-2.67 6.768-2.422 8.908-1.742 3.706 1.178 6.928 4.608 8.211 8.738a.874.874 0 11-1.671.52c-1.119-3.6-3.894-6.58-7.07-7.59-2.246-.715-4.519-.38-6.615.971 6.006 8.67 7.323 24.837 2.87 36.637-2.898 7.679-11.799 24.561-12.177 25.277a.863.863 0 01-.479.414l-7.091 2.536a.867.867 0 01-.294.051zM5.958 175.723l2.413 1.143 4.778-10.089c.845-1.783 2.535-2.714 4.025-3.537 1.296-.714 2.521-1.388 3.043-2.494l6.361-13.433c.663-1.4.45-2.705.201-4.217-.292-1.778-.62-3.792.51-6.183l5.673-11.977-2.415-1.144-6.303 13.308c-.898 1.899-.64 3.479-.366 5.154.271 1.65.551 3.355-.345 5.248l-5.098 10.768c-.766 1.617-2.3 2.462-3.781 3.278-1.381.761-2.687 1.482-3.289 2.753l-5.407 11.422zm2.83 3.183a.879.879 0 01-.375-.084l-3.995-1.893A.874.874 0 014 175.765l5.782-12.213c.846-1.784 2.534-2.715 4.025-3.535 1.297-.716 2.522-1.39 3.045-2.496l5.098-10.768c.663-1.4.45-2.705.201-4.215-.292-1.778-.623-3.794.51-6.187l6.676-14.099a.872.872 0 011.165-.415l4 1.892a.875.875 0 01.416 1.165l-6.047 12.766c-.9 1.899-.638 3.479-.365 5.154.27 1.65.55 3.355-.345 5.247L21.8 161.495c-.767 1.616-2.3 2.461-3.782 3.278-1.38.761-2.686 1.481-3.287 2.753l-5.153 10.88a.866.866 0 01-.791.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M30.57 132.032l6.74-14.235-3.998-1.893-6.74 14.234 3.997 1.894z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M27.736 129.723l2.415 1.143 5.993-12.653-2.416-1.142-5.992 12.652zm2.832 3.183a.867.867 0 01-.375-.084l-3.997-1.893a.872.872 0 01-.416-1.164l6.74-14.234a.873.873 0 011.165-.417l3.998 1.892c.21.1.371.278.449.497a.868.868 0 01-.032.668l-6.741 14.235a.873.873 0 01-.791.5zM4.873 186.302l6.741-14.234-3.999-1.892-6.739 14.235 3.997 1.891z"
        clipRule="evenodd"
      ></path>
      <mask id="mask2" width="13" height="19" x="0" y="169" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M0 169.301h12.488v17.877H0v-17.877z"></path>
      </mask>
      <g mask="url(#mask2)">
        <path
          fill="#394A58"
          fillRule="evenodd"
          d="M2.041 183.995l2.415 1.142 5.992-12.654-2.415-1.141-5.992 12.653zm2.832 3.183a.867.867 0 01-.375-.084l-3.997-1.892a.879.879 0 01-.416-1.165l6.739-14.235a.88.88 0 011.165-.418l4 1.892a.879.879 0 01.416 1.165l-6.741 14.236a.878.878 0 01-.791.501z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M71.292 109.202l11.89-25.108c3.463-7.316-7.744-14.317-11.676-6.013-5.056 10.676-.788 13.422-3.41 18.959l-4.119 8.699c-2.621 5.535 4.508 9.394 7.315 3.463z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M76.819 75.189c-1.677 0-3.388.87-4.522 3.267-2.844 6.002-2.645 9.326-2.468 12.259.13 2.149.25 4.179-.941 6.699l-4.12 8.699c-.672 1.416-.639 2.831.088 3.88.614.889 1.641 1.386 2.665 1.303 1.214-.098 2.273-.974 2.98-2.467l11.89-25.11c1.566-3.307-.368-6.64-3.142-7.955-.75-.355-1.587-.575-2.43-.575zm-9.522 37.866c-1.523 0-2.984-.77-3.88-2.067-1.092-1.578-1.176-3.627-.23-5.624l4.117-8.7c1.003-2.113.903-3.76.779-5.844-.18-2.988-.405-6.703 2.632-13.113 2.115-4.47 6.188-4.993 9.282-3.525 3.552 1.682 6.01 5.989 3.976 10.285l-11.89 25.109c-.973 2.053-2.583 3.315-4.419 3.465a5.053 5.053 0 01-.367.014zM45.942 226.489a.879.879 0 01-.374-.084l-9.884-4.681a.873.873 0 11.747-1.58l9.095 4.305 30.26-63.907-11.071-5.243a.874.874 0 11.747-1.58l11.863 5.616a.875.875 0 01.417 1.165l-31.009 65.489a.87.87 0 01-.79.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M48.178 214.1a.861.861 0 01-.374-.084l-6.722-3.182a.874.874 0 01-.417-1.165.879.879 0 011.166-.418l6.722 3.183a.874.874 0 01-.375 1.666zM55.046 199.594a.861.861 0 01-.374-.084l-6.722-3.183a.875.875 0 11.75-1.582l6.72 3.183a.875.875 0 01-.374 1.666zM61.916 185.088a.86.86 0 01-.375-.084l-6.722-3.183a.875.875 0 11.75-1.582l6.721 3.183a.875.875 0 01-.374 1.666zM68.784 170.581a.861.861 0 01-.375-.084l-6.722-3.181a.876.876 0 01.75-1.584l6.721 3.183a.875.875 0 01-.374 1.666z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M63.301 150.789l-33.23 70.182 4.744 2.247 33.23-70.182-4.744-2.247z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M31.235 220.556l3.162 1.496 32.482-68.6-3.16-1.498-32.484 68.602zm3.579 3.536a.873.873 0 01-.375-.084l-4.744-2.247a.872.872 0 01-.416-1.163l33.23-70.184a.87.87 0 01.497-.448.866.866 0 01.669.033l4.744 2.245a.875.875 0 01.417 1.166l-33.231 70.182a.873.873 0 01-.791.5zM125.139 95.84l-.984 2.076-6.463-3.06.984-2.078 6.463 3.061z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M118.858 94.44l4.881 2.31.234-.494-4.88-2.312-.235.495zm5.297 4.35a.879.879 0 01-.374-.084l-6.463-3.06a.875.875 0 01-.416-1.165l.981-2.077c.1-.21.28-.37.499-.448a.873.873 0 01.667.032l6.464 3.06a.875.875 0 01.417 1.166l-.984 2.075a.867.867 0 01-.791.5zM143.07 57.968l-.984 2.077-6.636-3.143.984-2.075 6.636 3.14z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M136.614 56.486l5.056 2.394.233-.497-5.054-2.392-.235.495zm5.473 4.435a.874.874 0 01-.375-.084l-6.638-3.143a.872.872 0 01-.449-.497.872.872 0 01.033-.67l.983-2.076a.879.879 0 011.166-.416l6.636 3.143a.875.875 0 01.418 1.165l-.983 2.077a.879.879 0 01-.791.5zM93.864 235.486H66.806a.875.875 0 010-1.75h27.058a.875.875 0 010 1.75zM61.119 235.486h-6.71a.875.875 0 010-1.75h6.71a.875.875 0 010 1.75zM49.014 235.486h-3.355a.875.875 0 010-1.75h3.355a.875.875 0 010 1.75zM194.888 59.684a.87.87 0 01-.658-.299c-4.347-4.966-9.809-9.028-14.984-11.14a.874.874 0 11.658-1.62c5.422 2.211 11.121 6.443 15.642 11.609a.872.872 0 01-.083 1.233.87.87 0 01-.575.218zM190.037 48.31a.877.877 0 01-.499-.156c-4.203-2.923-8.627-5.023-12.46-5.92a.873.873 0 01-.652-1.05.87.87 0 011.051-.653c4.043.945 8.68 3.141 13.059 6.184a.875.875 0 01-.499 1.595zM26.595 117.886a.903.903 0 01-.357-.075c-.44-.198-.638-.716-.44-1.157l4.196-9.38c.2-.443.716-.637 1.157-.443.439.198.638.716.439 1.157l-4.197 9.38a.876.876 0 01-.798.518zM24.59 111.528a.876.876 0 01-.798-1.232l2.855-6.38a.874.874 0 111.596.714l-2.854 6.38a.874.874 0 01-.799.518zM20.515 129.462a2.091 2.091 0 00-2.824.885 2.09 2.09 0 00.885 2.825l2.266 1.184 1.94-3.71-2.267-1.184z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M19.545 130.097a1.206 1.206 0 00-1.08.655c-.152.29-.181.619-.083.929.098.311.31.565.598.714l1.491.78 1.129-2.159-1.491-.779a1.207 1.207 0 00-.564-.14zm1.297 5.135a.867.867 0 01-.404-.1l-2.268-1.185a2.944 2.944 0 01-1.458-1.743 2.944 2.944 0 01.201-2.263 2.96 2.96 0 011.743-1.457 2.95 2.95 0 012.265.203l2.264 1.183a.876.876 0 01.371 1.181l-1.937 3.71a.879.879 0 01-.777.471z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M24.398 134.728a.867.867 0 01-.405-.1l-3.262-1.703a.875.875 0 11.81-1.552l3.26 1.705a.874.874 0 01-.404 1.65z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 62.966l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 63.84a.876.876 0 01-.619-1.495l6.125-6.124a.878.878 0 011.239 0 .878.878 0 010 1.24l-6.125 6.124a.881.881 0 01-.62.256z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 55.856l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 56.731a.876.876 0 01-.619-1.495l6.125-6.124a.878.878 0 011.239 0 .878.878 0 010 1.239l-6.125 6.125a.881.881 0 01-.62.255z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 48.747l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 49.622a.877.877 0 01-.619-1.495l6.125-6.125a.878.878 0 011.239 0 .878.878 0 010 1.24l-6.125 6.125a.88.88 0 01-.62.255z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 41.638l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 42.513a.877.877 0 01-.619-1.495l6.125-6.125a.878.878 0 011.239 0 .878.878 0 010 1.24l-6.125 6.124a.88.88 0 01-.62.256z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 34.528l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 35.403a.876.876 0 01-.619-1.495l6.125-6.124a.878.878 0 011.239 0 .878.878 0 010 1.239l-6.125 6.125a.881.881 0 01-.62.255z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 190.825l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 191.7a.876.876 0 01-.619-1.494l6.125-6.125a.876.876 0 011.239 1.239l-6.125 6.125a.884.884 0 01-.62.255z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 183.716l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 184.591a.876.876 0 01-.619-1.495l6.125-6.125a.878.878 0 011.239 0 .878.878 0 010 1.239l-6.125 6.125a.88.88 0 01-.62.256z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 176.606l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 177.481a.875.875 0 01-.619-1.494l6.125-6.125a.878.878 0 011.239 0 .878.878 0 010 1.239l-6.125 6.125a.88.88 0 01-.62.255z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 169.497l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 170.372a.876.876 0 01-.619-1.494l6.125-6.125a.876.876 0 011.239 1.239l-6.125 6.125a.884.884 0 01-.62.255z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 150.649l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 151.524a.876.876 0 01-.619-1.495l6.125-6.125a.878.878 0 011.239 0 .878.878 0 010 1.239l-6.125 6.125a.88.88 0 01-.62.256z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M107.06 143.539l6.125-6.125-6.125 6.125z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M107.059 144.414a.875.875 0 01-.619-1.494l6.125-6.125a.878.878 0 011.239 0 .878.878 0 010 1.239l-6.125 6.125a.88.88 0 01-.62.255z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M161.018 137.486c3.5-.145 9.187-1.02 11.957-1.895l-11.957 1.895z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M161.017 138.361a.876.876 0 01-.036-1.75c3.529-.145 9.119-1.029 11.732-1.855a.875.875 0 01.525 1.67c-2.776.876-8.469 1.781-12.184 1.935h-.037z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M189.892 74.632l-19.832 5.25 19.832-5.25z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M170.059 80.757a.874.874 0 01-.224-1.72l19.833-5.25a.872.872 0 011.069.621.872.872 0 01-.621 1.07l-19.833 5.25a.827.827 0 01-.224.03z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M186.392 63.549l-9.917 9.917 9.917-9.917z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M176.475 74.34a.876.876 0 01-.619-1.495l9.917-9.916a.878.878 0 011.239 0 .878.878 0 010 1.239l-9.917 9.917a.881.881 0 01-.62.256z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M89.56 219.153l.875 10.938-.875-10.938z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M90.432 230.966a.872.872 0 01-.87-.805l-.874-10.938a.875.875 0 01.801-.943c.467-.028.905.322.942.803l.875 10.938a.876.876 0 01-.802.943c-.024.002-.047.002-.072.002z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M92.622 221.341l-11.156 5.031 11.156-5.031z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M81.466 227.247a.877.877 0 01-.798-.514.878.878 0 01.44-1.159l11.155-5.031a.875.875 0 11.718 1.596l-11.156 5.031a.858.858 0 01-.36.077z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFC226"
        fillRule="evenodd"
        d="M305 126.677L331.921 137 337 123.325 310.079 113 305 126.677z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M305.917 126.865l25.377 9.578 4.788-12.688-25.377-9.579-4.788 12.689zm25.886 11.581a.88.88 0 01-.31-.057l-27.013-10.196a.875.875 0 01-.509-1.129l5.407-14.325a.872.872 0 011.127-.509l27.015 10.197c.45.171.679.675.509 1.127l-5.407 14.325a.874.874 0 01-.819.567z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#EDBDBD"
        fillRule="evenodd"
        d="M175 184.286L188.138 192c2.39-.482 5.227-2.908 6.998-6.022 2.307-4.05 8.194-14.87 9.864-17.938L192.274 161c-1.617 2.972-7.153 13.145-9.686 17.618-1.73 3.056-5.216 4.518-7.588 5.668z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M176.767 184.471l11.329 6.246c2.232-.448 4.879-2.705 6.533-5.602 2.153-3.767 7.648-13.832 9.207-16.686l-11.879-6.548c-1.509 2.765-6.676 12.227-9.041 16.388-1.615 2.842-3.935 5.133-6.149 6.202zm11.174 8.035a.867.867 0 01-.422-.109l-13.412-7.394a.875.875 0 01.35-1.638c1.853-.155 4.83-2.254 6.939-5.96 2.765-4.865 9.378-17.012 9.444-17.134a.875.875 0 011.192-.349l13.41 7.394a.876.876 0 01.347 1.185c-.072.129-7.124 13.079-9.639 17.482-2.006 3.512-5.259 6.131-8.092 6.514a.936.936 0 01-.117.009z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M195.614 168.032l-2.187 4.157 2.187-4.157z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M193.427 173.064a.858.858 0 01-.406-.102.871.871 0 01-.368-1.181l2.188-4.156a.873.873 0 011.179-.366.87.87 0 01.368 1.181l-2.188 4.157a.87.87 0 01-.773.467z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M198.786 169.782l-2.187 4.157 2.187-4.157z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M196.599 174.814a.855.855 0 01-.406-.102.87.87 0 01-.368-1.181l2.188-4.156a.872.872 0 011.179-.366.871.871 0 01.368 1.181l-2.188 4.157a.871.871 0 01-.773.467z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M331.062 129.247l-6.343-2.48 6.343 2.48z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M331.063 130.122a.891.891 0 01-.319-.059l-6.343-2.48a.875.875 0 11.637-1.631l6.343 2.48a.875.875 0 01-.318 1.69z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M329.531 132.601l-6.343-2.48 6.343 2.48z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M329.532 133.476a.891.891 0 01-.319-.059l-6.344-2.48a.877.877 0 01-.497-1.134.876.876 0 011.134-.495l6.344 2.478c.45.176.672.684.497 1.134a.876.876 0 01-.815.556z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#88D2D3"
        fillRule="evenodd"
        d="M278 79.417L287.748 90 309 70.585 299.254 60 278 79.417z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M278.422 79.313l9.168 9.994 19.988-18.334-9.166-9.996-19.99 18.336zm9.115 12.107c-.012 0-.026 0-.038-.002a.865.865 0 01-.606-.282l-10.351-11.284a.876.876 0 01.053-1.237l21.278-19.52a.918.918 0 01.63-.229.87.87 0 01.605.284l10.35 11.284a.876.876 0 01-.053 1.237l-21.276 19.52a.883.883 0 01-.592.229z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M295.01 68.904l4.028-3.353-4.028 3.353z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M295.01 69.779a.876.876 0 01-.56-1.547l4.027-3.353a.88.88 0 011.235.112.878.878 0 01-.113 1.232l-4.029 3.353a.87.87 0 01-.56.203z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M297.061 71.234l4.028-3.353-4.028 3.353z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M297.061 72.109a.876.876 0 01-.56-1.547l4.027-3.353a.88.88 0 011.235.112.878.878 0 01-.114 1.232l-4.028 3.353a.87.87 0 01-.56.203z"
        clipRule="evenodd"
      ></path>
      <path fill="#fff" fillRule="evenodd" d="M299.111 73.564l4.029-3.353-4.029 3.353z" clipRule="evenodd"></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M299.112 74.44a.876.876 0 01-.56-1.547l4.026-3.354a.88.88 0 011.236.112.878.878 0 01-.114 1.232l-4.028 3.353a.871.871 0 01-.56.203z"
        clipRule="evenodd"
      ></path>
      <mask id="mask3" width="56" height="76" x="226" y="0" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M226.44 0h55.197v75.765H226.44V0z"></path>
      </mask>
      <g mask="url(#mask3)">
        <path
          fill="#394A58"
          fillRule="evenodd"
          d="M255.145 9.783c-2.081 0-4.389.261-7.039.776-6.73 1.305-12.223 3.722-17.281 7.601-2.079 1.593-3.18 4.29-2.355 5.772.915 1.654 2.756 1.985 5.274 1.939 12.17-.156 24.021-4.447 33.572-11.783-.955-.815-2.098-1.563-3.449-2.262-2.65-1.376-5.399-2.043-8.722-2.043zm2.562 27.27c-7.088 0-14.432 2.193-19.584 4.962-1.892 1.018-3.682 2.801-3.406 4.025.296 1.3 3.024 1.354 5.595.833 7.289-1.482 15.946-5.245 21.936-9.496a30.73 30.73 0 00-4.541-.324zm-1.474 22.066c-3.848 0-8.697 1.133-13.329 4.263-1.11.75-1.434 1.591-1.267 2.055.183.51.983.773 2.033.675 6.563-.623 12.798-2.749 17.969-6.037-1.319-.583-3.199-.956-5.406-.956zm-5.244 16.646a.874.874 0 01-.212-1.724c5.782-1.44 10.327-3.977 12.159-6.79.936-1.436 1.297-3.153.964-4.59a3.707 3.707 0 00-.721-1.503c-5.519 3.66-12.252 6.022-19.341 6.697-2.284.222-3.482-.801-3.848-1.827-.49-1.363.269-2.971 1.932-4.095 8.428-5.69 17.691-5.16 21.299-3.01.023.012.044.026.065.039a32.778 32.778 0 004.725-4.108c1.533-1.622 5.059-5.806 4.721-9.602-.427-4.8-5.405-6.672-7.526-7.255-.209-.058-.42-.112-.632-.165-.116.09-.229.179-.348.266-6.188 4.666-15.659 8.882-23.564 10.49-4.356.885-7.137.096-7.649-2.16-.511-2.248 1.781-4.611 4.284-5.955 6.935-3.727 17.711-6.449 26.841-4.532 5.259-4.348 7.772-9.822 6.927-15.171-.336-2.121-1.138-3.901-2.489-5.444-9.884 7.654-22.181 12.134-34.809 12.295-1.698.018-5.215.065-6.829-2.84-1.407-2.532.37-6.13 2.82-8.01 5.285-4.051 11.007-6.571 18.012-7.93 7.214-1.4 12.268-.972 16.904 1.43 1.258.653 2.707 1.512 4.039 2.711A55.359 55.359 0 00280.03.396a.876.876 0 011.466.956 57.014 57.014 0 01-11.555 12.883c1.344 1.567 2.431 3.596 2.853 6.262.892 5.642-1.495 11.352-6.591 15.968 2.686.835 7.782 3.131 8.272 8.631.401 4.505-3.498 9.165-5.194 10.959a34.21 34.21 0 01-4.66 4.1 5.39 5.39 0 01.984 2.11c.439 1.892-.011 4.113-1.203 5.942-2.098 3.22-6.91 5.966-13.202 7.532a.877.877 0 01-.211.026z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M354.721 180.216a.875.875 0 01-.875-.875v-52.803a.875.875 0 011.75 0v52.803a.875.875 0 01-.875.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#88D2D3"
        fillRule="evenodd"
        d="M438.807 197.166c0-6.51-.836-13.558-7.035-18.573-4.66-3.775-9.061-7.338-9.061-12.856 0-12.538-10.164-22.704-22.705-22.704-12.538 0-22.704 10.166-22.704 22.704 0 9.601 3.435 16.809 10.502 22.04.871.642 1.688 1.225 2.45 1.769 4.579 3.264 6.028 4.461 6.028 7.903v19.252a2.163 2.163 0 004.325 0v-19.252c0-5.834-3.29-8.179-7.844-11.424-.74-.53-1.536-1.097-2.385-1.725-5.969-4.417-8.75-10.315-8.75-18.563 0-10.15 8.228-18.378 18.378-18.378 10.152 0 18.381 8.228 18.381 18.378 0 7.581 5.666 12.168 10.668 16.216 3.855 3.122 5.428 7.329 5.428 15.213"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M398.444 219.738a3.04 3.04 0 01-3.036-3.036V197.45c0-2.903-.929-3.817-5.663-7.193a151.508 151.508 0 01-2.46-1.778c-7.307-5.405-10.857-12.845-10.857-22.741 0-13.002 10.577-23.579 23.579-23.579 13.001 0 23.578 10.577 23.578 23.579 0 5.1 4.244 8.537 8.738 12.173 6.483 5.25 7.358 12.679 7.358 19.255a.874.874 0 11-1.75 0c0-6.473-.812-13.121-6.709-17.893-4.827-3.91-9.387-7.601-9.387-13.535 0-12.036-9.791-21.829-21.828-21.829-12.036 0-21.829 9.793-21.829 21.829 0 9.303 3.318 16.282 10.148 21.334.821.609 1.596 1.162 2.319 1.677 4.868 3.472 6.513 4.84 6.513 8.701v19.252c0 .708.578 1.286 1.286 1.286a1.29 1.29 0 001.287-1.286V197.45c0-5.385-2.974-7.504-7.475-10.712-.841-.602-1.596-1.141-2.399-1.734-6.211-4.597-9.103-10.719-9.103-19.266 0-10.617 8.636-19.255 19.253-19.255s19.255 8.638 19.255 19.255c0 7.163 5.494 11.61 10.343 15.535 4.14 3.353 5.754 7.808 5.754 15.893a.875.875 0 01-1.75 0c0-7.483-1.433-11.557-5.105-14.533-5.154-4.171-10.992-8.897-10.992-16.895 0-9.653-7.854-17.505-17.505-17.505s-17.503 7.852-17.503 17.505c0 7.945 2.667 13.62 8.394 17.859.795.588 1.542 1.122 2.242 1.62 4.718 3.366 8.341 5.947 8.341 12.233v19.252a3.04 3.04 0 01-3.037 3.036z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#394A58"
        fillRule="evenodd"
        d="M440.82 198.17h-9.077a.874.874 0 110-1.75h9.077a.875.875 0 010 1.75z"
        clipRule="evenodd"
      ></path>
    </StyledErrorImage>
  )
}

export default Error_large
