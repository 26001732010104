/* eslint-disable */
export default {
  common: {
    login: "Kirjaudu sisään",
    languageSelection: "Kielen valinta",
    new: "Uusi",
  },
  serviceLinkLabel: {
    services: 'Palvelut',
    ompaposti: 'OmaPosti',
    moving: 'Muuttoilmoitus',
    shop: 'Verkkokauppa',
    address: 'Osoitekirja',
  },
  accountRecovery: {
    initial: {
      title: "Palauta käyttäjätili",
      content: "Jos olet unohtanut kirjautumiseen käyttämäsi käyttäjänimen/sähköpostiosoitteen, voit palauttaa tilisi ja vaihtaa salasanasi tunnistautumalla verkkopankkitunnuksillasi, mobiilivarmenteella tai henkilökortilla. Jos et ole rekisteröinyt Posti-tiliä henkilötunnuksellasi, sinut ohjataan luomaan uusi tili Postin kuluttajapalveluihin.",
      buttonText: "Jatka tunnistautumiseen",
    },
    reset: {
      title: "Palauta tili",
      subTitle: "Hei {{name}}. Tervetuloa takaisin!",
      content0: "Voit palauttaa tilisi tarkistamalla ja päivittämällä Posti-tilisi tiedot alla. Voit muuttaa sähköpostiosoitettasi ja salasanaasi.",
      content1: "Sähköpostiosoitettasi käytetään kirjautumiseen Postin palveluihin.",
      sessionModal: {
        title: "Istuntosi päättyy pian.",
        content: "Sinut kirjataan ulos tilisi suojaamiseksi. Voit jatkaa tietojesi muokkaamista tai poistua tilin palautusnäkymästä.<1 />Istunto päättyy <0>{{time}}</0>.",
        exit: "Poistu",
        continue: "Jatka",
      },
    },
    corporate: {
      emailStep: {
        title: "Unohditko salasanasi?",
        content0: "Jos olet unohtanut salasanasi, voit nollata sen lähettämällä vahvistuskoodin kirjautumiseen käyttämääsi sähköpostiosoitteeseen. Jos et voi vastaanottaa vahvistuskoodia sähköpostitse, ota yhteyttä yritystilisi pääkäyttäjään.",
        content1: "Jos olet kuluttajakäyttäjä ",
        contentLink: "palauta tilisi vahvan asiakastunnistuksen kautta.",
        inputLabel: "Sähköposti",
        ctaLabel: "Lähetä vahvistuskoodi",
        mailReset: {
          title: "Sähköpostin vahvistus ja salasanan vaihto",
          content0: "Tunnukseen liittyvä sähköposti pitää vahvistaa ja salasana vaihtaa.",
        }
      },
      passwordStep: {
        title: "Aseta uusi salasana",
        content0: "Vahvistuskoodi on lähetetty osoitteeseen <0>{{email}}</0>.",
        content1: "Jos et saa sähköpostia tai antamasi sähköpostiosoite on väärä, ",
        content2: "Tarkista myös sähköpostisi roskapostikansio. Tarvittaessa ota yhteyttä organisaatiosi pääkäyttäjään.",
        contentLink: "yritä uudelleen.",
        codeInputLabel: "Vahvistuskoodi",
        ctaLabel: "Tallenna",
      },
      successStep: {
        title: "Salasana asetettu onnistuneesti",
        content: "Olet onnistuneesti asettanut salasanasi ja voit jatkaa kirjautumista uudella salasanalla. Muista pitää salasanasi turvassa.",
        ctaLabelLogin: "Jatka kirjautumista",
        ctaLabelBusinessChannels: "Siirry palvelukanaviin",
      },
      error: {
        codeValidation: "Virheellinen koodi",
      },
      message: {
        success: "Kuusinumeroinen vahvistuskoodi on lähetetty annettuun sähköpostiosoitteeseen.",
        badRequest: "Olet pyytänyt liian monta koodia. Yritä uudelleen tunnin kuluttua.",
        internalError: "Jotain meni pieleen. Yritä palauttaa tili myöhemmin.",
        codeExpired: "Vahvistuskoodi on vanhentunut. Pyydä uusi.",
        codeNotFound: "Annettua vahvistuskoodia ei löydy annetulle sähköpostiosoitteelle. Tarkista, oletko syöttänyt koodin oikein.",
        emailMismatch: "Annettua vahvistuskoodia ei löydy annetulle sähköpostiosoitteelle. Tarkista, oletko syöttänyt koodin oikein.",
        codeUsed: "Vahvistuskoodi on jo käytetty.",
        rateLimit: "Olet ylittänyt tunnikohtaisen nollausrajan. Yritä myöhemmin uudelleen.",
      },
    },
    time: {
      minute: "minuutti",
      minutes: "minuuttia",
      second: "sekunti",
      seconds: "sekuntia",
    },
  },
  invite: {
    loading: "Ladataan kutsua. Odota hetki.",
    acceptTitle: "Hyväksy kutsu yritykseltä {{orgName}}",
    registerTitle: "Luo yritystili",
    invalidTitle: "Hups, kutsua ei voi hyväksyä.",
    invalidContent: "Se saattaa johtua seuraavista asioista:",
    invalidReason1: "Kutsu on vanhentunut.",
    invalidReason2: "Kutsulinkki on rikki tai katkennut.",
    invalidReason3: "Kutsu on jo hyväksytty.",
    errorTitle: "Hups, jokin meni pieleen",
    errorContent: "Yritä myöhemmin uudelleen.",
    accept: {
      salute: "Hei {{email}},",
      preface: "Tervetuloa! Sinulle on lisätty oikeuksia Postin yrityspalveluihin.",
      joinMessage: "{{orgName}} ({{businessId}}) kutsuu sinut",
      invitedBy: "Kutsun lähetti pääkäyttäjä {{mainUserName}}",
      invitedByPosti: "Kutsun lähetti Posti",
      supportMessage: "Jos tarvitset apua, ota yhteyttä yrityksesi pääkäyttäjään.",
      acceptAndCreateButton: "Hyväksy ja luo tili",
      acceptButton: "Hyväksy",
      declineButton: "Hylkää",
    },
    register: {
      preface: "Sinut on kutsuttu käyttämään Postin yrityspalveluja. Luo ensin käyttäjätili.",
      orgInfo: "Käyttäjätili liitetään yritykseen {{ orgName }}.",
      usernameInfo: "Sähköpostiosoitteesi toimii palvelujen tunnuksena.",
      formTitle: "Täytä henkilökohtaiset tietosi",
      enterPassword: "Syötä salasana",
      passwordHelptext: "Salasanan on oltava vähintään 8 merkkiä. Siinä on oltava sekä isoja että pieniä kirjaimia, numeroita ja vähintään yksi erikoismerkki. Salasana ei voi olla sama kuin käyttäjätunnus (sähköpostiosoite).",
      firstName: "Etunimi",
      lastName: "Sukunimi",
      enter: "Syötä",
      enterPhoneNumber: "Syötä puhelinnumero (valinnainen)",
      phoneNumberHelp: "Suosittelemme, että annat myös puhelinnumerosi, vaikka kenttä ei ole pakollinen.",
      phoneNotValid: "Puhelinnumero ei ole oikeassa muodossa",
      firstNameNotValid: "Etunimi sisältää ei sallittuja merkkejä",
      firstNameEmpty: "Tarkista etunimi",
      lastNameNotValid: "Sukunimi sisältää ei sallittuja merkkejä",
      lastNameEmpty: "Tarkista sukunimi",
      backButton: "Takaisin",
      createAccountButton: "Luo käyttäjätili",
      hidePassword: "Piilota salasana",
      showPassword: "Näytä salasana",
      errorTitle: "Tarkista vielä:",
      lastNameHelptext: "Syötä sukunimi",
    },
    done: {
      acceptedTitle: "Hyväksytty",
      acceptedText: "Valmista tuli! Voit nyt käyttää Postin yrityspalveluita.",
      registeredTitle: "Käyttäjätili luotu",
      registeredText: "Tervetuloa Postin yrityspalveluihin, {{ name }}!",
      omapostiproButton: "Kirjaudu OmaPosti Prohon",
      smartshipButton: "Kirjaudu Smartshipiin",
      myaccountButton: "Kirjaudu tilillesi",
      registeredButton: "Kirjaudu OmaPosti Prohon",
    },
    footer: {
      title: "Tarvitsetko apua?",
      links: "<a href=\"https://www.posti.fi/fi/asiakastuki/ota-yhteytta#yritysasiakas\" target=\"_blank\">Asiakastuki</a>",
      path: "https://www.posti.fi/fi/asiakastuki/ota-yhteytta#yritysasiakas",
      label: "Asiakastuki",
    },
  },
  sideMenu: {
    myAccount: "Tilini",
    myDetails: "Kuluttajatietoni",
    myAddress: "Osoitteeni",
    mySettings: "Asetukset",
    myOrgs: "Organisaatiotietoni",
    userIdLink: "Kuluttajatietoni",
    feedback: {
      title: "Palaute ja tiedot",
      description: "Voit antaa palautetta Postin palveluista, tehdä muuttosuojauksen tai tehdä sinua koskevan tietopyynnön Postin rekistereistä.",
      link: "Tietopyyntö",
      mobileLink: "Tietopyyntö",
    },
  },
  detailsView: {
    phoneNumber: "Puhelinnumero",
    comLang: "Viestintäkieli",
    language: "Kieli",
    streetAddress: "Katuosoite",
    postalCode: "Postinumero",
    city: "Kaupunki",
    addressInfo: "Voit helposti tehdä muuttoilmoituksen Postille ja Digi- ja väestötietovirastolle sekä tarkistaa osoitetietosi muuttoilmoituksen sivuilla. Osoitehistoriasi näkyy rekisteriotteella 20 vuoden ajan. ",
    linkInfo: "Tee muuttoilmoitus",
    markConsent: "Markkinointiluvat",
    dataSharing: "Tietojen jakaminen Postin kumppaneille",
    contentText: "Sallimalla markkinoinnin sähköpostilla tai matkapuhelimella, saat ensimmäisenä tietoa ajankohtaisista asioista, uusista palveluista, palveluominaisuuksista, asiakaseduistamme ja kilpailuistamme.",
    contentIWantBenefits: "Haluan saada etuja Postilta",
    byEmail: "Sähköpostilla",
    infoPart0: "Haluan saada Postin tarkasti valitsemilta yhteistyökumppaneilta minulle kohdennettuja etuja. ",
    infoPart1: "Posti tai yhteistyökumppani voi lähettää sinulle kohdennettua mainontaa tai olla yhteydessä puhelimitse.",
    infoPart2: "Varmistamme aina, että tietojasi käsitellään asianmukaisesti. Sähköpostiosoitettasi ei anneta Postin ulkopuolelle.",
    byMail: "Kirjeitse",
    byPhone: "Puhelimitse",
    infoPart3: "Jos annat luvan yhdistää verkosta saatavia tietoja ",
    infoPart4: "(kuten Postin palvelujen selailutietoa), Postin yhteistyökumppanit voivat tarjota sinulle sopivia sisältöjä ja näet kohdennetumpaa markkinointia eri kanavissa. Valintasi ei vaikuta näkemäsi mainonnan määrään.",
    browserData: "Selailutietoni voi yhdistää asiakastietoihini",
    infoPart5: "Evästepohjaista sekä Postin oman mainonnan kohdentamista voit hallita ",
    infoHere: "Evästeet-sivulla.",
    infoPart55: " Lue lisää ",
    infoPart6: "tietosuojaperiaatteistamme.",
    infoPart7: "Yhteystietojen päivitys Postin kumppaneille",
    infoPart8: "Posti saa päivittää yhteystietoni asiakasorganisaatioilleen, joiden asiakas tai jäsen olen.",
    langError: "Kielen päivittämisessä tapahtui virhe",
    marketingError: "Markkinointilupien päivittämisessä tapahtui virhe",
    marketingSuccess: "Markkinointiluvat tallennettu",
    phoneInfo: "Pakollinen tieto kuluttajatilille.",
    serviceContractsTitle: "Sähköiset kulut­taja­palve­lut",
    contractAccepted: "Ehdot hyväksytty",
    rekisteriseloste: "Sähköisten kuluttaja­palveluiden rekisteri­seloste",
    accountTerminationDescription: "Halutessasi voit lopettaa Postin sähköisten palvelujen käyttämisen ja poistaa asiointitunnuksesi. Jos tunnus poistetaan, kaikkien kuluttajapalveluiden käyttäminen päättyy 14 vuorokauden päästä.",
    accountTerminationLink: "Lopeta Postin sähköisten palveluiden käyttö ",
    addressHistoryContent: "Voit tarkistaa omat osoitetietosi 10 vuoden ajalta. Näet raportissa vain omat tietosi, et esimerkiksi perheenjäsenten tietoja. Kirjaudu muuttoilmoituspalveluun tarkastamaan osoitetietosi.",
    addressMissingContent: "Osoitetiedot eivät ole tällä hetkellä saatavilla tässä palvelussa",
  },
  general: {
    cancel: "Peruuta",
    continue: "Jatka",
    saveChanges: "Tallenna muutokset",
    email: "Sähköpostiosoite",
    edit: "Muokkaa",
    close: "Sulje",
    password: "Salasana",
    lastName: "Sukunimi",
    preferredName: "Kutsumanimi",
    mobilePhoneNumber: "Puhelinnumero",
    error: "Jotakin meni pieleen. Kokeile myöhemmin uudestaan.",
    remove: "Poista",
    logout: "Kirjaudu ulos",
    loading: "Ladataan",
  },
  mainView: {
    title: "Tilitiedot",
    emailInfo: "Sähköpostiosoitteesi on myös käyttäjätunnuksesi.",
    firstNames: "Etunimet",
    loginDetails: "Kirjautumistiedot",
    name: "Nimi",
    preferredName: "Kutsumanimi",
    finishVerifyEmail: "Sinulla on keskeneräinen sähköpostin vaihto, vahvista sähköpostiosoitteesi.",
    editPhoneNumber: {
      title: "Vaihda puhelinnumero",
      currentPhoneNumber: "Nykyinen puhelinnumero",
      newPhoneNumber: "Uusi puhelinnumero",
      verifyInfoTitle: "Vahvista uusi puhelinnumerosi!",
      verifyInfoText: "Kuusinumeroinen vahvistuskoodi lähetetään uuteen puhelinnumeroosi.",
      verifyTitle: "Vahvista uusi puhelinnumerosi",
      verifyText: "Kuusinumeroinen vahvistuskoodi lähetettiin numeroon {{ newPhoneNumber }}. ",
      verifySuccess: "Puhelinnumerosi on vaihdettu.",
      verifying: "Varmistetaan koodia",
      invalidCode: "Koodi on väärä tai vanhentunut",
      invalidPhoneNumber: "Syötä suomalainen matkapuhelinnumero",
      sendNewCode: "Lähetä uusi koodi",
      codeWasResent: "Koodi lähetettiin uudelleen",
      tooSoonAfterFailed: "Odota hetki ennen kuin yrität uudelleen",
      failedCode: "Oho, koodi ehti vanhentua. Tilaa uusi koodi.",
    },
    editEmail: {
      title: "Vaihda sähköposti",
      currentEmail: "Nykyinen sähköposti",
      newEmail: "Uusi sähköposti",
      verifyTitle: "Vahvista uusi sähköpostisi!",
      validationError: "Tarkista kirjoitusasu",
      verifyText: "Lähetämme uuteen sähköpostiisi viestin. Klikkaa vahvistuslinkkiä viimeistelläksesi sähköpostin vaihdon. Linkki sähköpostissasi on voimassa 30 minuuttia. ",
      error: "Sähköpostin vaihtamisessa tapahtui virhe.",
      exitButton: "Palaa tilinäkymään",
      tryAgainButton: "Vaihda sähköposti",
      pendingVerification: "Odottaa vahvistusta",
    },
    changePassword: {
      title: "Vaihda salasana",
      oldPassword: "Vanha salasana",
      newPassword: "Uusi salasana (vähintään 8 merkkiä)",
      newPasswordAgain: "Vahvista uusi salasana",
      noMatch: "Salasanat eivät täsmää",
      error: {
        ERROR: "Salasanan vaihto epäonnistui",
        NEW_PASSWORD_NOT_VALID: "Salasanan on oltava vähintään 8 merkkiä. Siinä on oltava sekä isoja että pieniä kirjaimia, numeroita ja vähintään yksi erikoismerkki. Salasana ei voi olla sama kuin käyttäjätunnus (sähköpostiosoite).",
        NEW_PASSWORD_IS_SAME_AS_OLD_PASSWORD: "Uusi salasana ei voi olla sama kuin vanha",
        OLD_PASSWORD_IS_INCORRECT: "Vanha salasana oli väärin",
        NEW_PASSWORD_FIELDS_DO_NOT_MATCH: "Salasanat eivät täsmää",
      },
      success: {
        OK: "Salasana vaihdettu onnistuneesti",
      },
      logout: "Salasanasi on vaihdettu. Sinut kirjataan ulos hetken kuluttua. Kirjaudu uudelleen uudella salasanallasi.",
    },
    changeName: {
      firstName: "Etunimi",
      lastName: "Sukunimi",
      invalidEmptyFirstName: "Etunimi ei voi olla tyhjä",
      invalidEmptyLastName: "Sukunimi ei voi olla tyhjä",
      invalidCharacterFirstName: "Etunimi sisältää ei sallittuja merkkejä",
      invalidCharacterLastName: "Sukunimi sisältää ei sallittuja merkkejä",
      error: {
        ERROR: "Nimen vaihto epäonnistui",
        NAME_HAS_INVALID_CHARACTERS: "Nimen vaihto epäonnistui. Nimi sisältää ei sallittuja merkkejä",
      },
    },
    editPreferredName: {
      title: "Vaihda kutsumanimi",
      oldPreferredName: "Nykyinen kutsumanimi",
      newPreferredName: "Uusi kutsumanimi",
      error: "Kutsumanimen vaihto epäonnistui",
      success: "Kutsumanimen vaihto onnistui",
    },
    terminationInfo: {
      text: "Henkilökohtainen käyttäjätunnuksesi suljetaan lopullisesti {{date}}. Voit kirjautua normaalisti sisään Omapostiin kunnes tili suljetaan lopullisesti. Jos muutat mielesi, ole hyvä ja <0>ota yhteyttä Postin asiakaspalveluun.</0>",
    },
    accountTermination: {
      intro: "Voit halutessasi lopettaa Postin verkkopalveluiden käytön ja sulkea käyttäjätilisi.",
      accountTerminationLink: "Sulje tili",
    },
  },
  termination: {
    selection: {
      title_business: "Tilin lopetus",
      title_consumer: "Tilin lopetus",
      title_both: "Tilin lopetus",
      backlink: "Tilinhallinta",
      intro_business: "<p><strong>Valitse tili, niin pääset jatkamaan:</strong></p>",
      intro_consumer: "<p><strong>Valitse tili, niin pääset jatkamaan:</strong></p>",
      intro_both: "<p>Käytössäsi on henkilökohtainen tili ja yritystili. Valitse tili, jonka haluat lopettaa. Jos haluat lopettaa molemmat tilit, tee lopetus yksi tili kerrallaan.</strong></p>",
      error_accountTypeRequired: "Valitse tili, niin pääset jatkamaan.",
      back: "Takaisin",
      next: "Seuraava",
      consumer: {
        title: "Henkilökohtainen tili",
        description: "Tämä on henkilökohtainen tilisi, jolla käytät OmaPostia ja muita Postin kuluttajille suunnattuna palveluja.",
        b2b_needs_to_be_terminated_first: "Sinun pitää sulkea käytössäsi oleva yritystili, ennen kuin voit sulkea henkilökohtaisen tilin.",
      },
      business: {
        title: "Yritystili",
        description: "",
        and: "ja",
        admin_warning: "Olet ainoa pääkäyttäjä seuraaville yritystileille: {{organizationNames}}. Ole hyvä ja ",
        admin_warning_link: "siirrä oikeudet uudelle pääkäyttäjälle.",
      },
    },
    feedback: {
      title: "Ennen kuin suljemme tilisi...",
      subtitle: "Haluaisimme kuulla, miksi haluat lopettaa.",
      backlink: "Tilinhallinta",
      dontKnowHowToUseProduct: "En tiedä, miten palveluita käytetään.",
      foundAnotherProduct: "Löysin paremman palvelun, josta pidän enemmän.",
      didNotUseEnough: "Minulla ei ole tarpeeksi käyttöä palveluille.",
      missingFeatures: "Kaipaan monipuolisempia palveluita.",
      leavingFinland: "Muutan pois Suomesta.",
      privacyConsiderations: "Yksityisyys ja markkinointiviestit",
      other: "Muu syy",
      textInput: "Kerro lisää...",
      infoIconAlt: "Lisätietoja",
      privacyInfo: "Voit <a href=\"{{url}}\">päivittää markkinointiasetukset tästä</a>.",
      next_button: "Jatka tilin lopetukseen",
      back_button: "Takaisin",
    },
    confirmation: {
      backlink: "Tilinhallinta",
      thankYouForFeedback: "Kiitos palautteesta! ",
      input_required: "Pakollinen kenttä",
      back: "Takaisin",
      next: "Poista tili",
      dialog: {
        x_tooltip: "Sulje",
        cancel: "Peruuta",
        confirm: "Vahvista",
      },
      OrphanedAccountTermination: {
        title: "Vahvista tilin sulkeminen",
        subtitle: "",
        warning: "Tilisi poistetaan heti ja sinut kirjataan ulos Postin palveluista.",
        content: "\n        <p>{{feedbackThanks}}Ennen kuin suljet tilisi, huomioi...</p>\n        <p><strong>{{warning}}</strong></p>\n        <p>Mutta koska kyseessä ei ole henkilökohtainen kuluttajatunnuksesi eikä tunnuksella ole pääsyä mihinkään Postin yrityspalveluihin, se poistetaan joka tapauksessa automaattisesti muutaman viikon sisällä. Voit siis huoletta poistaa tunnuksen jo nyt.</p>\n        ",
        understand: "Olen varma, että haluan poistaa tilini.",
        dialogTitle: "Oletko varma?",
        dialogContent: "Tili poistetaan heti, etkä voi enää kirjautua Postin sähköisiin palveluihin.",
      },
      BusinessAccountTermination: {
        title: "Vahvista yritystilin lopetus",
        subtitle: "",
        warning_businessOnly: "Tunnuksesi ja käyttöoikeutesi yritystileihin poistetaan saman tien ja sinut kirjataan ulos Postin sähköisistä palveluista.",
        warning_consumer: "Käyttöoikeutesi yritystileihin poistetaan saman tien, mutta voit jatkaa Postin kuluttajapalveluiden käyttöä tällä tunnuksella.",
        content: "\n        <p>{{feedbackThanks}}Ennen kuin suljet tilisi, huomioi...</p>\n        <p><strong>{{warning}}</strong></p>\n        <span>Kun yritystili suljetaan, et voi enää käyttää näitä palveluja:</span>\n        <ul>\n          <li>Lähetysten tilaus ja seuranta </li>\n          <li>Raportointi ja analysointi, myös tärkeimpien laskutustietojen yhteenveto yhdellä näkymällä</li>\n          <li>Monikanavainen viestintä asiakkaillesi</li>\n        </ul>\n        <span>Nämä käyttöoikeudet poistetaan heti:</span>\n        <ul>\n          {{organizationList}}\n        </ul>\n        ",
        understand: "Olen varma, että haluan poistaa yritystilin käyttöoikeuteni.",
        dialogTitle: "Vahvista yritystilin lopetus?",
        dialogContent: "Käyttöoikeus yritystiliin poistetaan saman tien.",
      },
      ConsumerAccountTermination: {
        title: "Vahvista henkilökohtaisen tilin lopetus",
        subtitle: "",
        warning_consumerOnly: "Suljemme tilisi kahden viikon päästä ja poistamme kaikki tietosi noin kuukauden sisällä.",
        warning_business: "Henkilökohtainen tilisi suljetaan kahden viikon päästä ja poistamme kaikki tietosi noin kuukauden sisällä. Voit kuitenkin jatkaa Postin yrityspalveluiden käyttöä tällä samalla tunnuksella ja salasanalla.",
        content: "\n        <p>Moi{{name}},</p>\n        <p>{{feedbackThanks}}Ennen kuin suljet tilisi, huomioi...</p>\n        <p><strong>{{warning}}</strong></p>\n        <span>Kun suljet henkilökohtaisen tilisi, et voi enää käyttää näitä palveluita:</span>\n        <ul>\n          <li>OmaPosti: seuraa paketteja ja pidä laskut ja kirjeet tallessa.</li>\n          <li>Oma noutopiste: valitse mistä haet lähetyksen.</li>\n          <li>Postin ohjaus: tee muutoksia postin jakeluun helposti.</li>\n          <li>Osoitekirja: kaikki tärkeät osoitteet yhdessä paikassa.</li>\n        </ul>\n        ",
        understand: "Olen varma, että haluan sulkea tilin ja poistaa kaikki tietoni.",
        dialogTitle: "Vahvista henkilökohtaisen tilin poisto",
        dialogContent: "Vahvistuksen jälkeen käyttöoikeutesi päättyy 14 päivän kuluttua.",
      },
    },
    processing: {
      title: "Odota...",
      subtitle: "Tässä pitäisi kestää vain pieni hetki",
    },
    done: {
      OrphanedAccountTermination: {
        title: "Tilisi on suljettu",
        message: "<p>Toivottavasti nähdään taas pian!</p>",
        button: "WWW.POSTI.FI",
      },
      BusinessAccountTermination: {
        title: "Käyttöoikeutesi yritystiliin on lopetettu",
        message: "<p>Voit yhä käyttää tunnustasi Postin kuluttajille suunnatuissa sähköisissä palveluissa.</p>",
        button: "Palaa tilinäkymään",
      },
      ConsumerAccountTermination: {
        title: "Tilisi poistetaan kahden viikon kuluttua",
        message: "<p>Henkilökohtainen tilisi poistetaan kahden viikon kuluttua. Siihen asti voit jatkaa Postin sähköisten palveluiden käyttämistä. Jos sinulla on kirjeitä OmaPostissa, muista käydä tallentamassa ne omalle koneellesi ennen kuin tilisi suljetaan.</p>",
        button: "Palaa tilinäkymään",
      },
    },
    footer: {
      title: "Tarvitsetko apua?",
      links: "<a href=\"https://www.posti.fi/fi/asiakastuki\" target=\"_blank\">Asiakaspalvelu</a>",
      path: "https://www.posti.fi/fi/asiakastuki",
      label: "Asiakaspalvelu",
    },
  },
  myOrgsView: {
    title: "Organisaationi",
    organizationPhoneNumber: "Puhelinnumero",
    info: "Sinulle on myönnetty oikeudet seuraaviin organisaatioihin ja palveluihin.",
    askMoreInfo: "Jos sinulla on kysyttävää palvelujen käyttöön liittyen, ota yhteyttä organisaatiosi pääkäyttäjään.",
    editPhoneNumber: {
      error: "Puhelinnumeron vaihtaminen epäonnistui. Kokeile myöhemmin uudestaan.",
      success: "Puhelinnumeron vaihtaminen onnistui",
      invalidPhoneNumber: "Syötä kelpo puhelinnumero",
    },
    customerNumbersContracts: "Asiakasnumerot ja sopimukset",
    customerNumbers: "Asiakasnumerot",
    logisticsServiceContractNumbers: "Logistiikan sopimustunnukset",
    transportIds: "Rahdin kuljetustunnukset",
    mainUsers: "Pääkäyttäjät",
    leaveOrg: "Poistu organisaatiosta",
    editOrg: "Muokkaa organisaatiota",
    modalTitle: "Organisaatiosta poistuminen",
    modalText: "Menetät kaikki oikeudet käyttää Postin palveluja tämän organisaation nimissä.",
  },
  errors: {
    general: {
      title: "Jotakin meni pieleen",
    },
    badUrl: {
      title: "Tämä linkki ei ole voimassa",
    },
    accountTermination: {
      generic: {
        title: "Jotakin meni pieleen",
        message: "Tilin sulkeminen epäonnistui. Yritä uudelleen.",
      },
    },
    refresh: "Takaisin alkuun",
    generalErrorWithRetry: {
      somethingWentWrong: "Jotakin meni pieleen.",
      tryAgain: "Kokeile myöhemmin uudestaan",
    },
  },
  csat: {
    title: "Miten kuvailisit kokemustasi?",
    thanks: {
      title: "Kiitos palautteestasi!",
      text: "Palautteesi auttaa meitä parantamaan palvelua.",
    },
    terrible: {
      label: "Surkea",
      aria: "Surkea kokemus",
    },
    bad: {
      label: "Huono",
      aria: "Huono kokemus",
    },
    ok: {
      label: "Okei",
      aria: "Okei kokemus",
    },
    good: {
      label: "Hyvä",
      aria: "Hyvä kokemus",
    },
    great: {
      label: "Loistava",
      aria: "Loistava kokemus",
    },
  },
  registration: {
    exitModal: {
      title: "Poistu rekisteröinnistä ja kirjaudu ulos",
      text: "Jos poistut nyt, voit jatkaa rekisteröintiä myöhemmin.",
    },
    before: {
      title: "Luo Postin käyttäjätili",
      hello: "Hei!",
      welcomeToPosti: "Hienoa, että olet rekisteröitymässä Postin palvelujen käyttäjäksi.\n\nKun rekisteröidyt, voit käyttää ilmaista OmaPostia ja muita palvelujamme.  OmaPosti on paras sovelluksena puhelimessa, mutta voit käyttää sitä myös netissä ilman asennuksia.\n\nSuosittelemme OmaPostia, koska:",
      bullets: {
        omaPosti: "OmaPosti helpottaa pakettien seuraamista ja noutamista. Pysyt parhaiten ajan tasalla pakettisi matkasta, kun kirjaudut OmaPostiin.",
        pickupPoint: "Kotiin kertyvän paperin määrää voit vähentää tilaamalla kirjeitä ja laskuja OmaPostin sähköiseen postilaatikkoon.",
      },
    },
    accountAlreadyExists: {
      viewTitle: "Käyttäjätilisi on valmiina",
      youAlreadyHaveAccount: "Sinulla on Postin käyttäjätili sähköpostiosoitteella",
      loginOrForgotPasswordInstruction: "Kirjaudu sisään sähköpostiosoitteella ja salasanallasi klikkaamalla nappia alta. Tarvittaessa voit vaihtaa unohtuneen salasanasi.",
      buttonLoginWithEmail: "Jatka kirjautumiseen",
      buttonForgotMyPassword: "Unohdin salasanani",
    },
    underMinAge: {
      title: "Rekisteröityminen ei ole mahdollista",
      notificationText: "Et valitettavasti voi rekisteröityä Postin verkkopalvelujen käyttäjäksi.",
      goToPostiFiButtonText: "Jatka posti.fihin",
      descriptionText: "Voit käyttää palveluita, jos:",
      descriptionList: "<0>Sinulla on suomalainen henkilötunnus.</0>\n<0>Olet vähintään 15-vuotias.</0>\n<0>Tietosi ovat Postin osoiterekisterissä.</0>\n<0>Tämä käyttäjätili on ainoa käytössäsi oleva tili. Jos sinulla on jo tili toisella henkilötunnuksella, ota yhteyttä Postin asiakaspalveluun.</0>",
    },
    incomplete: {
      title: "Täydennä tarvittavat tiedot",
      text: "Tervetuloa takaisin! Kun täydennät tietosi, pääset käyttämään Postin verkkopalveluita. Klikkaa alla olevaa nappia ja kirjaudu sisään pankkitunnuksilla. Sen jälkeen voit täydentää puuttuvat tiedot.",
    },
    create: {
      title: "Luo käyttäjätili",
      titleOldUsers: "Viimeistele käyttäjätilisi",
      topic: "Anna omat tietosi",
      topicExplanation: "Rekisteröitymällä luot itsellesi Posti-tilin, joka antaa sinulle pääsyn Posti Groupin yhtiöiden (”Posti”) eri digitaalisiin palveluihin, kuten OmaPostiin ja sen sisältämiin palveluihin. Palvelut on kuvattu Postin verkkosivuilla, palveluihin sovellettavissa ehdoissa ja/tai kyseisen palvelun yhteydessä.",
      enterPhone: "Anna puhelinnumero",
      enterEmail: "Anna sähköpostiosoite",
      enterPassword: "Anna salasana",
      phoneInstructions: "On tärkeää, että annat oman henkilökohtaisen puhelinnumerosi. Tähän numeroon lähetetään esimerkiksi pakettien saapumisilmoitukset. Puhelinnumeron pitää olla suomalainen.",
      emailInstructions: "Sähköpostiosoitteesi toimii käyttäjätilisi käyttäjätunnuksena. Tunnistaudut sillä Postin palveluihin.",
      passwordInstructions: "Salasanan on oltava vähintään 8 merkkiä. Siinä on oltava sekä isoja että pieniä kirjaimia, numeroita ja vähintään yksi erikoismerkki. Salasana ei voi olla sama kuin käyttäjätunnus (sähköpostiosoite).",
      preferredNameInstructions: "Voit halutessasi lisätä kutsumanimen.",
      termsOfUseHeadline: "Käyttöehdot, markkinointiluvat ja asiakasviestintä",
      optional: "Valinnainen",
      acceptTerms: {
        iAcceptThe: "Hyväksyn ",
        termsOfUseLinkText: "käyttöehdot",
        andHaveReadThe: " ja olen lukenut ",
        privacyStatementLinkText: "tietosuojaselosteen",
      },
      acceptRegistryUpdate: {
        label: "Hyväksyn, että Posti voi päivittää yhteystietoni asiakasorganisaatioilleen, joiden asiakas tai jäsen olen.",
        additionalInfo: "Huomioithan, että Posti Jakelu Oy voi silti päivittää yhteystietosi osoiterekisteristä niille organisaatioille, joilla on jo yhteystietosi, jos sinulla ei ole luovutuskieltoa. Löydät lisätietoja luovutuskiellosta ja sen tekemisestä ",
        additionalInfoLinkText: "täältä.",
      },
      acceptMarketing: "Haluan vastaanottaa uutisia ja tarjouksia Postilta ja Postin kumppaneilta sähköisesti. Suostumuksen voi peruuttaa milloin tahansa.",
      links: {
        termsOfUse: "https://www.posti.fi/fi/asiakastuki/ehdot-ja-tietosuoja/kateis-ja-kuluttaja/sahkoisten-kuluttajapalveluiden-kayttoehdot",
        privacyStatement: "https://www.posti.fi/fi/asiakastuki/ehdot-ja-tietosuoja/tietosuoja/postin-sahkoisten-kuluttajapalveluiden-asiakasrekisteri",
        disclosureBan: "https://www.posti.fi/fi/henkiloille/kirjeet-ja-postipalvelut/jakelu-ja-muuttaminen/tietojen-luovuttamisen-kieltaminen",
      },
      youMayTerminate: "Voit lakkauttaa Posti-tilisi milloin tahansa.",
      validation: {
        enterPhone: "Tarkista puhelinnumero",
        enterEmail: "Tarkista sähköpostiosoite",
        enterPassword: "Tarkista salasana",
        preferredName: "Kutsumanimi ei saa sisältää välilyöntejä eikä voi olla pidempi kuin 100 merkkiä.",
        acceptTerms: "Hyväksy käyttöehdot jatkaaksesi käyttäjätilin luomista",
      },
      oldUserNotification: "Näyttää siltä, että sinulla on jo Postin käyttäjätili, mutta joitakin tietoja puuttuu. Pääset käyttämään tiliä, kun tarkistat tiedot ja täydennät ne.",
      errorMessages: {
        emailTaken: "Sähköpostiosoite on jo käytössä. Ole hyvä ja syötä toinen sähköpostiosoite. Jos olet aiemmin luonut tilin Postin yrityspalveluihin, et voi käyttää samaa sähköpostiosoitetta uudelleen.",
        validationError: "Antamissasi tiedoissa on virhe. Tarkista tiedot ja kokeile uudestaan.",
      },
    },
    verifyPhone: {
      title: "Vahvista puhelinnumerosi",
      codeSentTo: "Kuusinumeroinen vahvistuskoodi on lähetetty tekstiviestillä numeroon",
      countdown: "Vahvistuskoodi on voimassa",
      expired: "Vahvistuskoodi on vanhentunut. Pyydä uutta tai vaihda puhelinnumeroa.",
      newCodeSent: "Uusi vahvistuskoodi on lähetetty puhelinnumeroosi.",
      enterCode: "Anna vahvistuskoodi.",
      inputLabel: "Vahvistuskoodi",
      didNotReceive: "Etkö saanut vahvistuskoodia?",
      sendNew: "Pyydä uusi koodi",
      reEnterPhone: "Anna puhelinnumerosi uudestaan",
      validation: {
        codeError: "Vahvistuskoodissa pitää olla kuusi numeroa",
        codeSuccess: "Vahvistuskoodissa on kuusi numeroa",
      },
      errorMessages: {
        invalidCode: "Koodi on väärä tai vanhentunut.",
        tooSoonAfterFailed: "Odota hetki ennen kuin yrität uudelleen.",
        failedCode: "Koodi on vanhentunut. Pyydä uusi koodi.",
        pleaseTryAgainOr: "Yritä uudelleen tai",
        requestNew: "pyydä uusi koodi.",
        sendNewCodeError: "Uuden vahvistuslinkin lähettäminen epäonnistui. Näin voi käydä, jos vahvistuslinkki on jo lähetetty monta kertaa. Kokeile uudestaan tunnin kuluttua.",
        tooManyTimesTryAgain: "Näin voi käydä, jos vahvistuslinkki on jo lähetetty monta kertaa. Kokeile uudestaan tunnin kuluttua.",
      },
      reEnterPhoneModal: {
        instruction: "Anna uusi suomalainen puhelinnumerosi alle. Saat tekstiviestinä koodin, jolla voit vahvistaa puhelinnumerosi.",
        sendCodeButton: "Pyydä vahvistuskoodi",
      },
    },
    verifyEmail: {
      title: "Vahvista sähköpostiosoitteesi",
      weSentYou: "Vahvistusviesti on lähetetty sähköpostiosoitteeseen:",
      email: "Sähköposti",
      instruction: "Viimeistele rekisteröinti klikkaamalla vahvistuslinkkiä, joka lähetettiin antamaasi sähköpostiosoitteeseen.",
      windowCanBeClosedInfo: "Voit sulkea tämän ikkunan tai välilehden sähköpostiosoitteen vahvistamisen jälkeen.",
      didNotReceive: "Etkö saanut sähköpostin vahvistuslinkkiä?",
      checkSpamOr: "Tarkista roskapostilaatikkosi tai",
      reEnterEmail: "Kirjoita sähköpostiosoite uudestaan",
      sendNew: "Pyydä uusi linkki",
      newLinkSent: "Uusi vahvistuslinkki on lähetetty sähköpostiosoitteeseesi.",
      errorMessages: {
        sendNewLinkError: "Uuden vahvistuslinkin lähettäminen epäonnistui. Näin voi käydä, jos vahvistuslinkki on jo lähetetty monta kertaa. Kokeile uudestaan tunnin kuluttua.",
        tooManyTimesTryAgain: "Näin voi käydä, jos vahvistuslinkki on jo lähetetty monta kertaa. Kokeile uudestaan tunnin kuluttua.",
        reserved: "Sähköpostiosoite on jo käytössä.",
      },
      reEnterEmailModal: {
        instruction: "Anna sähköpostiosoitteesi alle. Saat sähköpostiin linkin, jolla voit vahvistaa osoitteesi.",
        sendLinkButton: "Pyydä vahvistuslinkki",
      },
    },
    buttons: {
      proceedToCreate: "Jatka tilin luontiin",
      createAccount: "Luo käyttäjätili",
      updateAccount: "Päivitä käyttäjätili",
      backToPosti: "Takaisin Postin palveluihin",
      gotoOmaPosti: "Siirry OmaPostiin",
      exitRegistration: "Poistu",
      exit: "Poistu",
    },
    setMissingPhone: {
      title: "Tarkista tietosi",
      instruction: "Tiedoistasi puuttuu vielä puhelinnumero, joka tarvitaan palvelujen käyttöä varten. Seuraavaksi sinut ohjataan puhelinnumeron vahvistukseen. Klikkaa jatka ja seuraa ohjeita.",
      errorMessages: {
        setPhoneNumberError: "Puhelinnumeron tallennus epäonnistui, kokeile uudestaan.",
      },
    },
  },
  accountVerified: {
    success: {
      registrationTitle: "Käyttäjätili on vahvistettu!",
      emailChangedTitle: "Sähköpostiosoitteesi on vaihdettu",
      welcome: "Tervetuloa! Käyttäjätilisi on nyt vahvistettu. Voit kirjautua OmaPostiin, jossa voit seurata pakettien kulkua ja vastaanottaa kirjeitä ja laskuja.",
      continueToMyAccount: "Omat tiedot",
    },
    failure: {
      title: "Sähköpostin vahvistaminen epäonnistui",
      verificationFailedDueTo: "Sähköpostiosoitteen vahvistaminen epäonnistui. Se voi johtua seuraavista syistä:",
      reason1: "Vahvistuskoodi on väärä",
      reason2: "Vahvistuskoodi on vanhentunut",
      reason3: "Vahvistuskoodi on jo käytetty",
      pleaseTryAgainAndReturnToMyAccount: "Palaa käyttäjätilin tietoihin ja yritä uudestaan.",
      pleaseTryAgainAndReturnToRegistration: "Palaa rekisteröitymiseen ja yritä uudestaan.",
      returnToMyAccount: "Palaa käyttäjätilin tietoihin",
      returnToRegistration: "Palaa rekisteröitymiseen",
    },
  },
  footer: {
    cookiePreferences: "Evästeasetukset",
  },
}
