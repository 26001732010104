/* eslint-disable */
export default {
  common: {
    login: "Logga in",
    languageSelection: "Välj språk",
    new: "Ny",
  },
  serviceLinkLabel: {
    services: 'Tjänster',
    ompaposti: 'OmaPosti',
    moving: 'Flyttanmäla',
    shop: 'Näthandel',
    address: 'Adressbok',
  },
  accountRecovery: {
    initial: {
      title: "Återställ ditt personliga konto",
      content: "Om du har glömt ditt användarnamn/din e-postadress som du använder för att logga in, kan du återställa ditt konto och ditt lösenord genom att autentisera med dina nätbankskoder, Mobile ID eller identitetskort. Om du inte har registrerat ett Posti-konto med din personbeteckning kommer du att omdirigeras för att skapa ett nytt konto för Postis konsumenttjänster.",
      buttonText: "Fortsätt till identifiering",
    },
    reset: {
      title: "Återställ ditt konto",
      subTitle: "Hej {{name}}. Välkommen tillbaka!",
      content0: "För att återställa ditt konto, vänligen granska och uppdatera dina Posti-kontouppgifter nedan. Du kan ändra din e-postadress och ditt lösenord.",
      content1: "Din e-postadress används för att logga in på Postis tjänster.",
      sessionModal: {
        title: "Din session kommer att löpa ut snart.",
        content: "Du kommer att loggas ut för att skydda ditt konto. Du kan fortsätta att redigera dina uppgifter eller gå bort från kontots återställningsvy.<1 />Sessionen löper ut om <0>{{time}}</0>.",
        exit: "Avsluta",
        continue: "Fortsätt",
      },
    },
    corporate: {
      emailStep: {
        title: "Glömt ditt lösenord",
        content0: "Om du har glömt ditt lösenord kan du återställa det med en verifieringskod som skickas till den e-postadress som du använder för att logga in. Om du inte kan ta emot verifieringskoden via e-post, vänligen kontakta huvudanvändaren för ditt företagskonto för hjälp.",
        content1: "Om du är konsumentanvändare, vänligen ",
        contentLink: "återställ ditt konto via Strong Customer Authentication.",
        inputLabel: "E-post",
        ctaLabel: "Skicka verifieringskod",
        mailReset: {
          title: "Bekräftelse av e-post och byte av lösenord",
          content0: "E-posten som är kopplad till kontot måste bekräftas och lösenordet måste bytas.",
        }
      },
      passwordStep: {
        title: "Skapa nytt lösenord",
        content0: "En 6-siffrig verifieringskod har skickats till <0>{{email}}</0>.",
        content1: "Om du inte får ett e-postmeddelande som innehåller verifieringskoden eller den angivna e-postadressen är felaktig, vänligen gå ",
        content2: "Se till att kontrollera din skräppostmapp om du inte ser e-postmeddelandet i din inkorg. Om det fortfarande inte fungerar, vänligen kontakta huvudanvändaren för ditt företagskonto för hjälp.",
        contentLink: "tillbaka och försök igen.",
        codeInputLabel: "Verifieringskod",
        ctaLabel: "Spara",
      },
      successStep: {
        title: "Lösenordsåterställning lyckades",
        content: "Du har framgångsrikt återställt ditt lösenord och kan fortsätta att logga in med det nya lösenordet. Kom ihåg att hålla ditt lösenord säkert.",
        ctaLabelLogin: "Fortsätt till inloggning",
        ctaLabelBusinessChannels: "Gå till servicekanaler",
      },
      error: {
        codeValidation: "Ogiltig kod",
      },
      message: {
        success: "Den 6-siffriga verifieringskoden har skickats till den angivna e-postadressen.",
        badRequest: "Du har begärt för många koder, försök igen om en timme.",
        internalError: "Något gick fel, försök att återställa ditt konto senare.",
        codeExpired: "Verifieringskoden har gått ut, begär en ny.",
        codeNotFound: "Den inmatade verifieringskoden hittas inte för den angivna e-postadressen. Kontrollera om du har angett koden korrekt.",
        emailMismatch: "Den inmatade verifieringskoden hittas inte för den angivna e-postadressen. Kontrollera om du har angett koden korrekt.",
        codeUsed: "Verifieringskoden har redan använts.",
        rateLimit: "Du har överskridit din timgräns för återställning, försök igen senare.",
      },
    },
    time: {
      minute: "minut",
      minutes: "minuter",
      second: "sekund",
      seconds: "sekunder",
    },
  },
  invite: {
    loading: "Laddar inbjudan. Vänta ett ögonblick.",
    acceptTitle: "Godkänn inbjudan från företaget {{orgName}}",
    registerTitle: "Skapa ett företagskonto",
    invalidTitle: "Hoppsan, inbjudan kan inte godkännas.",
    invalidContent: "Det kan bero på något av följande:",
    invalidReason1: "Inbjudan har gått ut.",
    invalidReason2: "Inbjudningslänken är trasig eller bristfällig.",
    invalidReason3: "Inbjudan har redan godkänts.",
    errorTitle: "Hoppsan, något gick fel",
    errorContent: "Var vänlig och försök igen senare.",
    accept: {
      salute: "Bästa kund ({{email}}),",
      preface: "Välkommen! Du har beviljats utökade rättigheter i Postis företagstjänster.",
      joinMessage: "{{orgName}} ({{businessId}}) bjuder in dig",
      invitedBy: "Inbjudan skickad av administratör {{mainUserName}}",
      invitedByPosti: "Inbjudan skickad av Posti",
      supportMessage: "Om du behöver hjälp, vänligen kontakta administratören i ditt företag.",
      acceptAndCreateButton: "Godkänn och skapa ett konto",
      acceptButton: "Godkänn",
      declineButton: "Avböj",
    },
    register: {
      preface: "Du har inbjudits att använda Postis företagstjänster. Först måste du skapa ett användarkonto.",
      orgInfo: "Användarkontot sammankopplas med företaget {{ orgName }}.",
      usernameInfo: "Din e-postadress fungerar som ditt användarnamn i tjänsterna.",
      formTitle: "Fyll i dina personliga uppgifter",
      enterPassword: "Ange lösenord",
      passwordHelptext: "Lösenordet måste bestå av minst 8 tecken. Det måste innehålla både stora och små bokstäver, siffror och minst ett specialtecken. Lösenordet får inte vara detsamma som användarnamnet (e-postadressen).",
      firstName: "Förnamn",
      lastName: "Efternamn",
      enter: "Ange",
      lastNameHelptext: "Ange efternamn",
      enterPhoneNumber: "Ange telefonnummer (valfritt)",
      phoneNumberHelp: "Vi rekommenderar att du också anger ditt telefonnummer, även om fältet inte är obligatoriskt.",
      phoneNotValid: "SV: Telefonnumret har fel format",
      firstNameNotValid: "Förnamnet har otillåtna tecken",
      firstNameEmpty: "Kontrollera förnamnet",
      lastNameNotValid: "Efternamnet har otillåtna tecken",
      lastNameEmpty: "Kontrollera efternamnet",
      backButton: "Tillbaka",
      createAccountButton: "Skapa ett användarkonto",
      hidePassword: "Dölj lösenordet",
      showPassword: "Visa lösenordet",
      errorTitle: "Var god och kontrollera {{ is }} {{numeral}} {{ problem }}.",
      problemSingular: "problem",
      problemPlural: "problem",
      isSingular: "nästa",
      isPlural: "följande",
      one: "",
      two: "två",
      three: "tre",
      four: "fyra",
    },
    done: {
      acceptedTitle: "Godkänd",
      acceptedText: "Allt klart! Du kan nu använda Postis företagstjänster.",
      registeredTitle: "Användarkonto skapat",
      registeredText: "Välkommen att använda Postis företagstjänster, {{ name }}!",
      registeredButton: "Logga in på OmaPosti Pro",
      omapostiproButton: "Logga in på OmaPosti Pro",
      smartshipButton: "Logga in på Smartship",
      myaccountButton: "Logga in på ditt konto",
    },
    footer: {
      title: "Behöver du hjälp?",
      links: "<a href=\"https://www.posti.fi/sv/kundsupport/kontakta-oss#foretagskund\" target=\"_blank\">Kundsupport</a>",
      path: "https://www.posti.fi/sv/kundsupport/kontakta-oss#foretagskund",
      label: "Kundsupport",
    },
  },
  sideMenu: {
    myAccount: "Mitt konto",
    myDetails: "Mina konsumentuppgifter",
    myAddress: "Min adress",
    mySettings: "Inställningar",
    myOrgs: "Min organisation",
    userIdLink: "Mina konsumentuppgifter",
    feedback: {
      title: "Respons och data",
      description: "Du kan ge respons på Postis tjänster, gör ett flyttskydd eller lämna en informationsbegäran om uppgifter som gäller dig i Postis register.",
      link: "Dataförfrågan",
      mobileLink: "Dataförfrågan",
    },
  },
  detailsView: {
    phoneNumber: "Telefonnummer",
    comLang: "Kontaktspråk",
    language: "Språk",
    streetAddress: "Gatuadress",
    postalCode: "Postnummer",
    city: "Ort",
    addressInfo: "Du kan enkelt skicka en flyttanmälan till både Posti och Myndigheten för digitalisering och befolkningsdata och kontrollera dina adresser på adressändringssajten. Din adresshistorik för de senaste 20 åren kommer att visas på registerutdraget.",
    linkInfo: "Gör flyttanmälan",
    markConsent: "Marknadsföringstillstånd",
    dataSharing: "Datadelning med Postis partner",
    contentText: "När du tillåter marknadsföring via e-post eller mobiltelefon är du en av de första som får information om aktuella ärenden, nya tjänster och tjänsteegenskaper, och om våra kundförmåner och tävlingar.",
    contentIWantBenefits: "Jag vill ha information om Postis kundförmåner, erbjudanden och tävlingar",
    byEmail: "Via e-post",
    infoPart0: "Jag vill få förmåner av Postis utvalda samarbetspartner. ",
    infoPart1: "Posti eller Postis samarbetspartner kan skicka riktad reklam till dig eller kontakta dig per telefon. ",
    infoPart2: " Vi ser alltid till att dina uppgifter behandlas på ett ansvarsfullt sätt. Din e-postadress lämnas inte ut utanför Posti.",
    byMail: "Per post",
    byPhone: "Per telefon",
    infoPart3: "Om du ger tillstånd att länka uppgifter som fås från webben",
    infoPart4: "Postis samarbetspartners kan erbjuda passande innehåll till dig och du ser mer riktad marknadsföring i olika kanaler, när du ger ditt tillstånd att förena information som kan fås från nätet (som Postis tjänsters bläddringsinformation) med dina kunduppgifter. Ditt val påverkar inte mängden av reklam som du ser..",
    browserData: "Mina bläddringsuppgifter kan förenas med mina kunduppgifter",
    infoPart5: "Du kan styra kakbaserad samt riktning av Postis egen marknadsföring ",
    infoHere: "på sidan Cookies. ",
    infoPart55: " Läs mer ",
    infoPart6: "om våra dataskyddsprinciper.",
    infoPart7: "Uppdatering av uppgifter",
    infoPart8: "Mina kontaktuppgifter kan uppdateras av Posti till deras kundorganisationer där jag är kund eller medlem.",
    langError: "Det uppstod ett fel i uppdateringen av språk",
    marketingError: "Det uppstod ett fel i uppdateringen av marknadsföringstillstånd",
    marketingSuccess: "Marknadsföringstillstånd har sparats",
    phoneInfo: "Obligatorisk uppgift för konsumentkonto.",
    serviceContractsTitle: "Elektroniska konsu­menttjäns­ter",
    contractAccepted: "Villkoren godkändes",
    rekisteriseloste: "Registerbeskrivning för de elektroniska konsumenttjänsterna",
    accountTerminationDescription: "Om du vill kan du sluta använda Postis elektroniska tjänster och ta bort din ärendekod. Om koden tas bort, slutar användningen av alla konsumenttjänster om 14 dygn.",
    accountTerminationLink: "Sluta använda Postis elektroniska tjänster",
    addressHistoryContent: "Du kan kontrollera din egen adressinformation under 10 år. Du ser bara dina egna data i rapporten, inte till exempel familjemedlemmar. Logga in på Flyttanmälan för att se din adressinformation.",
    addressMissingContent: "Adressuppgifter är inte tillgängliga i den här tjänsten för tillfället",
  },
  general: {
    cancel: "Ångra",
    continue: "Fortsätt",
    saveChanges: "Spara ändringar",
    email: "E-postadress",
    edit: "Redigera",
    close: "Stäng",
    password: "Lösenord",
    lastName: "Efternamn",
    preferredName: "Tilltalsnamn",
    mobilePhoneNumber: "Telefonnummer",
    error: "Något gick fel. Försök på nytt senare.",
    remove: "Radera",
    logout: "Logga ut",
    loading: "Laddas",
  },
  mainView: {
    title: "Kontouppgifter",
    emailInfo: "Din e-postadress är också ditt användarnamn.",
    firstNames: "Förnamn",
    loginDetails: "Inloggningsdetaljer",
    name: "Namn",
    preferredName: "Tilltalsnamn",
    finishVerifyEmail: "Du har en ofullständig e-poständring, vänligen verifiera din e-postadress.",
    editPhoneNumber: {
      title: "Byt telefonnummer",
      currentPhoneNumber: "Aktuellt telefonnummer",
      newPhoneNumber: "Nytt telefonnummer",
      verifyInfoTitle: "Bekräfta ditt nya telefonnummer!",
      verifyInfoText: "En sexsiffrig bekräftelsekod skickas till ditt nya telefonnummer.",
      verifyTitle: "Bekräfta ditt nya telefonnummer",
      verifyText: "En sexsiffrig bekräftelsekod skickades till nummer {{ newPhoneNumber }}. ",
      verifySuccess: "Ditt telefonnummer har bytts.",
      verifying: "Koden kontrolleras",
      invalidCode: "Koden är fel eller föråldrad",
      invalidPhoneNumber: "Ange ett finskt mobiltelefonnummer",
      sendNewCode: "Skicka ny kod",
      codeWasResent: "Koden skickades igen",
      tooSoonAfterFailed: "Vänta en stund innan du försöker igen",
      failedCode: "Koden har slutat gälla. Skicka en ny kod nedan",
    },
    editEmail: {
      title: "Byt e-post",
      currentEmail: "Nuvarande e-post",
      newEmail: "Ny e-post",
      verifyTitle: "Bekräfta din nya e-postadress!",
      validationError: "Kontrollera stavningen",
      verifyText: "Vi skickar meddelandet till din nya e-postadress. Klicka på bekräftelselänken för att slutföra e-postutbytet. Länken i din e-post är giltig i 30 minuter.",
      error: "Ett fel uppstod vid byte av e-post.",
      exitButton: "Tillbaka till mitt konto",
      tryAgainButton: "Byt e-post",
      pendingVerification: "Väntar verifiering",
    },
    changePassword: {
      title: "Byt lösenord",
      oldPassword: "Gammalt lösenord",
      newPassword: "Nytt lösenord (minst 8 tecken)",
      newPasswordAgain: "Bekräfta nytt lösenord",
      noMatch: "Lösenorden överensstämmer inte",
      error: {
        ERROR: "Byte av lösenord misslyckades",
        NEW_PASSWORD_NOT_VALID: "Lösenordet måste bestå av minst 8 tecken. Det måste innehålla både stora och små bokstäver, siffror och minst ett specialtecken. Lösenordet får inte vara detsamma som användarnamnet (e-postadressen).",
        NEW_PASSWORD_IS_SAME_AS_OLD_PASSWORD: "Det nya lösenordet kan inte vara samma som det gamla",
        OLD_PASSWORD_IS_INCORRECT: "Gammalt lösenord var fel",
        NEW_PASSWORD_FIELDS_DO_NOT_MATCH: "Lösenorden överensstämmer inte",
      },
      success: {
        OK: "Lösenordet har bytts",
      },
      logout: "Ditt lösenord har ändrats. Du kommer att loggas ut om några sekunder. Vänligen logga in med det nya lösenordet.",
    },
    changeName: {
      firstName: "Förnamn",
      lastName: "Efternamn",
      invalidEmptyFirstName: "Förnamnet får inte vara tomt",
      invalidEmptyLastName: "Efternamnet får inte vara tomt",
      invalidCharacterFirstName: "Förnamnet har otillåtna tecken",
      invalidCharacterLastName: "Efternamnet har otillåtna tecken",
      error: {
        ERROR: "Byte av namn misslyckades",
        NAME_HAS_INVALID_CHARACTERS: "Byte av namn misslyckades. Namn har otillåtna tecken",
      },
    },
    editPreferredName: {
      title: "Byt tilltalsnamn",
      oldPreferredName: "Nuvarande tilltalsnamn",
      newPreferredName: "Nytt tilltalsnamn",
      error: "Byte av tilltalsnamn misslyckades",
      success: "Byte av tilltalsnamn lyckades",
    },
    terminationInfo: {
      text: "Ditt personliga konto kommer att slutgiltigt stängas {{date}}. Du kan fortfarande logga in till Omaposti innan detta datum. Om du innan detta datum ångrar dig och inte vill stänga ditt konto, <0>ta kontakt med Postis kundtjänst.</0>",
    },
    accountTermination: {
      accountTerminationLink: "Ta bort ditt Posti-konto",
      intro: "Om du vill kan du sluta använda Postis elektroniska tjänster och ta bort ditt konto.",
    },
  },
  termination: {
    selection: {
      title_business: "Välj konton som ska tas bort",
      title_consumer: "Välj konton som ska tas bort",
      title_both: "Detta är de konton du kan ta bort",
      backlink: "Mitt konto",
      intro_business: "<p><strong>Välj kontotyp för att gå vidare:</strong></p>",
      intro_consumer: "<p><strong>Välj kontotyp för att gå vidare:</strong></p>",
      intro_both: "<p>Du har både personligt konto och organisationskonto. Välj ett för att gå vidare. Om du vill avsluta båda kan du komma tillbaka till detta flöde för att ta bort det andra.</p><p><strong>Välj ett för att gå vidare:</strong></p>",
      error_accountTypeRequired: "Välj minst ett konto för att gå vidare",
      back: "Föregående",
      next: "Nästa",
      consumer: {
        title: "Personligt konto",
        description: "Detta är kontot du använder för att öppna Omaposti och andra privata Posti-tjänster.",
        b2b_needs_to_be_terminated_first: "Ditt företagskonto måste avslutas innan du kan stänga ditt personliga konto.",
      },
      business: {
        title: "Organisationskonto",
        description: "",
        and: "och",
        admin_warning: "Hej, du är den sista huvudanvändaren för {{organizationNames}}. För att säkerställa att någon tar hand om detta konto ",
        admin_warning_link: "ska du utnämna en ny huvudanvändare innan du lämnar.",
      },
    },
    feedback: {
      title: "Innan du lämnar oss...",
      subtitle: "Kan du berätta varför du lämnar oss?",
      backlink: "Mitt konto",
      dontKnowHowToUseProduct: "Jag vet inte hur er produkt ska användas",
      foundAnotherProduct: "Jag har hittat en annan produkt jag gillar mer",
      didNotUseEnough: "Jag använde det inte tillräckligt",
      missingFeatures: "Funktioner saknas",
      leavingFinland: "Jag lämnar Finland",
      privacyConsiderations: "Dataskyddsöverväganden och marknadskommunikation",
      other: "Andra skäl",
      textInput: "Berätta mer ...",
      infoIconAlt: "Information",
      privacyInfo: "Du kan <a href={{url}}>inaktivera marknadskommunikation här</a>.",
      next_button: "Gå vidare till borttagning",
      back_button: "Tillbaka",
    },
    confirmation: {
      backlink: "Mitt konto",
      thankYouForFeedback: "Tack för din feedback. ",
      input_required: "Obligatoriskt fält",
      back: "Tillbaka",
      next: "Ta bort konto",
      dialog: {
        x_tooltip: "Stäng",
        cancel: "Avbryt",
        confirm: "Bekräfta",
      },
      OrphanedAccountTermination: {
        title: "Bekräfta kontoborttagning",
        subtitle: "",
        warning: "Om du väljer att fortsätta med kontoborttagningen kommer kontot att tas bort omedelbart och du kommer inte längre att kunna logga in med ditt Posti-konto.",
        content: "\n        <p>Hej {{name}}!</p>\n        <p>{{feedbackThanks}}Innan vi tar farväl vill vi att du ska veta följande.</p>\n        <p><strong>{{warning}}</strong></p>\n        <p>Eftersom ditt konto inte är länkat till din privata identitet och inte har åtkomst till några organisationer kommer det att tas bort automatiskt om några veckor ändå. Så att ta bort ditt konto nu bör vara ganska säkert.</p>\n        ",
        understand: "Jag är säker på att jag vill ta bort mitt konto.",
        dialogTitle: "Är du säker?",
        dialogContent: "Ditt konto kommer att avslutas omedelbart och du kan inte logga in på Postis onlinetjänster längre.",
      },
      BusinessAccountTermination: {
        title: "Ta bort ditt organisationskonto",
        subtitle: "",
        warning_businessOnly: "Ditt konto och din åtkomst till organisationerna kommer att avlägsnas direkt och du kommer att loggas ut från Postis onlinetjänster",
        warning_consumer: "Ditt konto och din åtkomst till organisationerna kommer att avlägsnas direkt men du kan fortfarande logga in och använda Postis konsumenttjänster",
        content: "\n        <p>Hej {{name}}!</p>\n        <p>{{feedbackThanks}}Det var tråkigt att höra att du lämnar oss. Innan vi tar farväl vill vi att du ska veta följande.</p>\n        <p><strong>{{warning}}</strong></p>\n        <span>När du avslutat kommer du inte att kunna använda alla dessa tjänster:</span>\n        <ul>\n          <li>Skicka och spåra dina kunders leveranser på ett effektivt sätt</li>\n          <li>Rapport- och analysverktyg samt en fakturasammanfattning som redovisar den viktigaste versionen i en och samma vy.</li>\n          <li>Flera kanaler att kommunicera med kunderna i</li>\n        </ul>\n        <span>Du kommer omedelbart tas bort från:</span>\n        <ul>\n          {{organizationList}}\n        </ul>\n        ",
        understand: "Jag är säker på att jag vill lämna alla organisationerna.",
        dialogTitle: "Bekräfta borttagning av organisationskonto?",
        dialogContent: "Din åtkomst till organisationer kommer att dras tillbaka omedelbart.",
      },
      ConsumerAccountTermination: {
        title: "Ta bort ditt personliga konto",
        subtitle: "",
        warning_consumerOnly: "När kontot tagits bort kommer din rätt att använda alla konsumenttjänster avslutas efter 14 dagar. Dina uppgifter kommer att tas bort i sin helhet efter ungefär en månad.",
        warning_business: "Ditt personliga konto kommer att avslutas efter 14 dagar och alla dina personuppgifter kommer att tas bort efter ungefär en månad. Du kan fortfarande använda Postis företagstjänster med samma användarnamn och lösenord.",
        content: "\n        <p>Hej {{name}}!</p>\n        <p>{{feedbackThanks}}Det var tråkigt att höra att du lämnar oss. Innan vi tar farväl vill vi att du ska veta följande.</p>\n        <p><strong>{{warning}}</strong></p>\n        <span>När avslutet är fullbordat kommer du inte att kunna använda alla dessa tjänster:</span>\n        <ul>\n          <li>OmaPosti, enkel paketspårning och försändelsemottagning, faktura i realtid.</li>\n          <li>Ditt uthämtningsställe, det fasta uthämtningsställe du föredrar.</li>\n          <li>Vidarebefordra post, ändra enkelt din adress och vidarebefordra din post till önskad plats.</li>\n          <li>Adressbok, håll adresserna till dina nära och kära uppdaterade.</li>\n        </ul>\n        ",
        understand: "Jag är säker på att jag vill stänga det personliga kontot och radera alla relaterade uppgifter.",
        dialogTitle: "Bekräfta borttagning av personligt konto",
        dialogContent: "Efter bekräftelse kommer din rätt att använda alla konsumenttjänster upphöra efter 14 dagar.",
      },
    },
    processing: {
      title: "Vänta ...",
      subtitle: "Detta ska bara ta en stund",
    },
    done: {
      OrphanedAccountTermination: {
        title: "Konto borttaget",
        message: "<p>Det är tråkigt att du lämnar oss, men vi hoppas att syns igen snart!</p>",
        button: "WWW.POSTI.FI",
      },
      BusinessAccountTermination: {
        title: "Organisationskonto borttaget",
        message: "<p>Vi har tagit bort dig från alla organisationer, men du kan fortfarande använda Postis onlinetjänster som konsument.</p>",
        button: "Tillbaka till mitt konto",
      },
      ConsumerAccountTermination: {
        title: "Du konto kommer att tas bort om två veckor",
        message: "<p>Ditt personliga konto kommer att tas bort om två veckor. Tills dess kan du fortsätta använda Postis onlinetjänster. Om du har några brev sparade i OmaPosti ska du komma ihåg att spara dem på din egen dator innan ditt konto avslutas.</p>",
        button: "Tillbaka till mitt konto",
      },
    },
    footer: {
      title: "Behöver du hjälp?",
      links: "<a href=\"https://www.posti.fi/sv/kundsupport\" target=\"_blank\">Kundsupport</a>",
      path: "https://www.posti.fi/sv/kundsupport",
      label: "Kundsupport",
    },
  },
  myOrgsView: {
    title: "Min organisation",
    organizationPhoneNumber: "Telefonnummer",
    info: "Du har beviljats rättigheter till följande organisationer och tjänster.",
    askMoreInfo: "Om du har frågor om användningen av tjänsterna ska du kontakta din organisations administratör.",
    editPhoneNumber: {
      error: "Byte av telefonnummer misslyckades. Försök på nytt senare.",
      success: "Byte av telefonnummer lyckades",
      invalidPhoneNumber: "Ange giltigt telefonnummer",
    },
    customerNumbersContracts: "Kundnummer och avtal",
    customerNumbers: "Kundnummer",
    logisticsServiceContractNumbers: "Logistikavtalsnummer",
    transportIds: "Transportkoder",
    mainUsers: "Administratörer",
    leaveOrg: "Lämna organisation",
    editOrg: "Redigera organisation",
    modalTitle: "Utträde ur organisation",
    modalText: "Du mister alla rättigheter att använda Postis tjänster i denna organisations namn.",
  },
  errors: {
    general: {
      title: "Något gick fel",
    },
    badUrl: {
      title: "Denna länk är inte giltig",
    },
    accountTermination: {
      generic: {
        title: "Något gick fel",
        message: "Ditt kontoavslut misslyckades. Försök igen ...",
      },
    },
    refresh: "Tillbaka till början",
    generalErrorWithRetry: {
      somethingWentWrong: "Något gick fel. Var god",
      tryAgain: "försök igen",
    },
  },
  csat: {
    title: "Hur skulle du beskriva din upplevelse?",
    thanks: {
      title: "Tack för din respons!",
      text: "Din respons hjälper oss att förbättra tjänsten.",
    },
    terrible: {
      label: "Förfärlig",
      aria: "En förfärlig upplevelse",
    },
    bad: {
      label: "Dålig",
      aria: "En dålig upplevelse",
    },
    ok: {
      label: "Okej",
      aria: "En okej upplevelse",
    },
    good: {
      label: "Bra",
      aria: "En bra upplevelse",
    },
    great: {
      label: "Utmärkt",
      aria: "En utmärkt upplevelse",
    },
  },
  registration: {
    exitModal: {
      title: "Avsluta registreringen och logga ut",
      text: "Om du tar paus nu kan du fortsätta din registrering senare.",
    },
    before: {
      title: "Skapa ett Posti-användarkonto",
      hello: "Hej!",
      welcomeToPosti: "Vad roligt att du håller på att registrera dig som användare av Postis tjänster.\n\nNär du registrerar dig kan du använda kostnadsfria OmaPosti och våra andra tjänster. OmaPosti fungerar bäst som en app på din telefon, men du kan också använda den på nätet utan installationer.\n\nVi rekommenderar OmaPosti eftersom:",
      bullets: {
        omaPosti: "OmaPosti gör det enklare att spåra och hämta paket. Du får full koll på ditt pakets resa genom att logga in på OmaPosti.",
        pickupPoint: "Du kan minska mängden papper som samlas hemma genom att styra brev och fakturor till OmaPostis elektroniska postlåda.",
      },
    },
    accountAlreadyExists: {
      viewTitle: "Ditt användarkonto är redo att användas",
      youAlreadyHaveAccount: "Du har Postis användarkonto med e-postadressen",
      loginOrForgotPasswordInstruction: "Logga in med din e-postadress och ditt lösenord genom att klicka på knappen nedan. Om du har glömt ditt lösenord kan du ändra det vid behov.",
      buttonLoginWithEmail: "Fortsätt till inloggningen",
      buttonForgotMyPassword: "Jag har glömt mitt lösenord",
    },
    underMinAge: {
      title: "Registrering är inte möjlig",
      notificationText: "Tyvärr kan du inte registrera dig som användare av Postis nättjänster.",
      goToPostiFiButtonText: "Fortsätt till posti.fi",
      descriptionText: "Du kan använda tjänsterna om:",
      descriptionList: "<0>Du har en finsk personbeteckning.</0>\n<0>Du är minst 15 år gammal.</0>\n<0>Dina uppgifter finns i Postis adressregister.</0>\n<0>Detta användarkonto är det enda kontot du har. Om du redan har ett konto med en annan personbeteckning ska du kontakta Postis kundtjänst.</0>",
    },
    incomplete: {
      title: "Fyll i de uppgifter som krävs",
      text: "Välkommen tillbaka! När du kompletterar dina uppgifter får du tillgång till Postis nättjänster. Klicka på knappen nedan och logga in med dina nätbankskoder. Efter det kan du fylla i de uppgifter som saknas.",
    },
    create: {
      title: "Skapa ett användarkonto",
      titleOldUsers: "Slutför ditt användarkonto",
      topic: "Ange dina uppgifter",
      topicExplanation: "Genom att registrera dig skapar du ett personligt Posti-konto som ger dig tillgång till olika digitala tjänster från Posti-koncernbolag (”Posti”), till exempel OmaPosti och de tjänster den innehåller. Tjänsterna beskrivs på Postis webbplats, i gällande villkor och/eller i samband med respektive digitala tjänst.",
      enterPhone: "Ange telefonnumme",
      enterEmail: "Ange e-postadress",
      enterPassword: "Ange lösenord",
      phoneInstructions: "Det är viktigt att du anger ditt personliga telefonnummer. Till detta nummer skickas till exempel meddelanden om att paket har anlänt. Telefonnumret måste vara finskt.",
      emailInstructions: "Din e-postadress fungerar som användarnamn för ditt användarkonto. Du kan identifiera dig med den i Postis tjänster.",
      passwordInstructions: "Lösenordet måste bestå av minst 8 tecken. Det måste innehålla både stora och små bokstäver, siffror och minst ett specialtecken. Lösenordet får inte vara detsamma som användarnamnet (e-postadressen).",
      preferredNameInstructions: "Du kan lägga till tilltalsnamnet om du vill.",
      termsOfUseHeadline: "Användarvillkor, samtycke och kundkommunikation",
      optional: "Valfritt",
      acceptTerms: {
        iAcceptThe: " Jag accepterar ",
        termsOfUseLinkText: "användarvillkoren",
        andHaveReadThe: " och har läst ",
        privacyStatementLinkText: "dataskyddspolicyn",
      },
      acceptRegistryUpdate: {
        label: "Jag vill få nyheter och erbjudanden från Posti och deras partner via digitala kanaler. Samtycket kan när som helst återkallas.",
        additionalInfo: "Observera att dina kontaktuppgifter fortfarande kan uppdateras av Posti Jakelu Oy från adressregistret till sådana organisationer som redan har dina kontaktuppgifter, om du inte har förbjudit tillgängliggörande av din adress. Du kan hitta mer information och förbjuda tillgängliggörande i enlighet med instruktionerna ",
        additionalInfoLinkText: "här.",
      },
      acceptMarketing: "Jag accepterar att mina kontaktuppgifter kan uppdateras av Posti till respektive kundorganisation där jag är kund eller medlem.",
      links: {
        termsOfUse: "https://www.posti.fi/sv/kundsupport/villkor-och-dataskydd/kontant-och-konsument/anvandningsvillkor-for-elektroniska-konsumenttjanster",
        privacyStatement: "https://www.posti.fi/sv/kundsupport/villkor-och-dataskydd/dataskydd/postis-kundregister-for-elektroniska-konsumenttjanster",
        disclosureBan: "https://www.posti.fi/sv/privat/brev-och-posttjanster/utdelning-och-adressandring/kontaktuppgifter",
      },
      youMayTerminate: "Du kan när som helst avsluta ditt Posti-konto.",
      validation: {
        enterPhone: "Kontrollera telefonnummer",
        enterEmail: "Kontrollera e-postadress",
        enterPassword: "Kontrollera lösenord",
        preferredName: "Tilltalsnamnet får inte innehålla mellanslag och får inte vara längre än 100 tecken.",
        acceptTerms: "Godkänn användarvillkoren för att fortsätta skapa användarkonto",
      },
      oldUserNotification: "Det verkar som om du redan har ett Posti-användarkonto, men vissa uppgifter saknas. Du får tillgång till kontot genom att kontrollera och komplettera uppgifterna.",
      errorMessages: {
        emailTaken: "E-postadressen är redan i bruk. Vänligen ange en annan e-postadress. Om du tidigare har skapat ett konto hos Postis företagstjänster, kan du inte använda samma e-postadress igen.",
        validationError: "Det finns ett fel i de uppgifter du angav. Kontrollera uppgifterna och försök igen.",
      },
    },
    verifyPhone: {
      title: "Bekräfta ditt telefonnummer",
      codeSentTo: "En sexsiffrig bekräftelsekod har skickats via SMS till numret",
      countdown: "Bekräftelsekoden är giltig för",
      expired: "Bekräftelsekoden har löpt ut. Begär en ny eller ändra telefonnummer.",
      newCodeSent: "En ny bekräftelsekod har skickats till ditt telefonnummer.",
      enterCode: "Ange bekräftelsekod.",
      inputLabel: "Bekräftelsekod",
      didNotReceive: "Fick du ingen bekräftelsekod?",
      sendNew: "Skicka en ny kod",
      reEnterPhone: "Ange ditt telefonnummer igen",
      validation: {
        codeError: "Bekräftelsekoden måste innehålla sex siffror",
        codeSuccess: "Bekräftelsekoden innehåller sex siffror",
      },
      errorMessages: {
        invalidCode: "Koden är fel eller föråldrad.",
        tooSoonAfterFailed: "Vänta en stund innan du försöker igen.",
        failedCode: "Koden har slutat gälla. Skicka en ny kod nedan.",
        pleaseTryAgainOr: "Försök igen eller",
        requestNew: "begära en ny kod.",
        sendNewCodeError: "Det gick inte att skicka en ny bekräftelselänk. Detta kan hända om bekräftelselänken redan har skickats flera gånger. Försök igen om en timme.",
        tooManyTimesTryAgain: "Detta kan hända om bekräftelselänken redan har skickats flera gånger. Försök igen om en timme.",
      },
      reEnterPhoneModal: {
        instruction: "Ange ditt nya finska telefonnummer nedan. Du får en kod via SMS för att bekräfta ditt telefonnummer.",
        sendCodeButton: "Skicka bekräftelsekod",
      },
    },
    verifyEmail: {
      title: "Bekräfta din e-postadress",
      weSentYou: "Ett bekräftelsemeddelande har skickats till följande e-postadress:",
      email: "E-post",
      instruction: "Slutför din registrering genom att klicka på bekräftelselänken som skickats till den e-postadress du angett.",
      windowCanBeClosedInfo: "Du kan stänga det här fönstret eller fliken efter att du har bekräftat e-postadressen.",
      didNotReceive: "Fick du ingen bekräftelselänk via e-post?",
      checkSpamOr: "Kontrollera din skräppostlåda eller",
      reEnterEmail: "Skriv in e-postadressen igen",
      sendNew: "Skicka en ny länk",
      newLinkSent: "En ny bekräftelselänk har skickats till din e-postadress.",
      errorMessages: {
        sendNewLinkError: "Det gick inte att skicka en ny bekräftelselänk. Detta kan hända om bekräftelselänken redan har skickats flera gånger. Försök igen om en timme.",
        tooManyTimesTryAgain: "Detta kan hända om bekräftelselänken redan har skickats flera gånger. Försök igen om en timme.",
        reserved: "E-postadressen är redan i bruk.",
      },
      reEnterEmailModal: {
        instruction: "Ange din e-postadress nedan. Du får ett e-postmeddelande med en länk för att bekräfta din adress.",
        sendLinkButton: "Skicka bekräftelselänk",
      },
    },
    buttons: {
      proceedToCreate: "Fortsätt till skapa konto",
      createAccount: "Skapa ett användarkonto",
      updateAccount: "Updatera kontot",
      backToPosti: "Tillbaka till Postis tjänster",
      gotoOmaPosti: "Gå till OmaPosti",
      exitRegistration: "Lämna",
      exit: "Lämna",
    },
    setMissingPhone: {
      title: "Kontrollera dina uppgifter",
      instruction: "Det saknas fortfarande ett telefonnummer i dina uppgifter. Det behövs för att du ska få tillgång till tjänsterna. Snart kommer du att uppmanas att verifiera ditt telefonnummer. Klicka på fortsätt och följ instruktionerna.",
      errorMessages: {
        setPhoneNumberError: "Det gick inte att spara telefonnumret, försök igen.",
      },
    },
  },
  accountVerified: {
    success: {
      registrationTitle: "Användarkontot har verifierats!",
      emailChangedTitle: "Din e-postadress har bytts",
      welcome: "Välkommen! Ditt användarkonto har nu verifierats. Du kan logga in i OmaPosti där du kan följa med var paketen befinner sig och ta emot brev och fakturor.",
      continueToMyAccount: "Mitt konto",
    },
    failure: {
      title: "Kunde inte verifiera e-postadressen",
      verificationFailedDueTo: "Verifieringen av e-postadressen misslyckades. Detta kan bero på något av följande:",
      reason1: "Bekräftelsekoden är felaktig",
      reason2: "Bekräftelsekoden har gått ut",
      reason3: "Bekräftelsekoden har redan använts",
      pleaseTryAgainAndReturnToMyAccount: "Gå tillbaka till uppgifterna i användarkontot och försök igen.",
      pleaseTryAgainAndReturnToRegistration: "Gå tillbaka till registreringen och försök igen.",
      returnToMyAccount: "Gå tillbaka till uppgifterna i användarkontot",
      returnToRegistration: "Gå tillbaka till registreringen",
    },
  },
  footer: {
    cookiePreferences: "Kakinställningar",
  },
}
